import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import secureLocalStorage from 'react-secure-storage';

const ShowEmpNotification = () => {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [showToast, setShowToast] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate('/sign-in');
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchLiveNotification();
  }, []);

  const handleViewClick = async (type, ticket_id) => {
    if (type === 'wfh_status') {
      navigate('/apply-wfh');
    }
    if (type === 'leave_status') {
      navigate('/emp-leave');
    }
    if (type === 'wfh') {
      navigate('/wfh-list');
    }
    if (type === 'leave') {
      navigate('/leave-details');
    }
    if (type === 'ticket_raise') {
      try {
        const response = await dashboardApi.post('ticket-notification-seen-status', {
          emp_id: emp_id,
          type: 'ticket_raise',
        });
        
        if (response.data.status === 200) {
          navigate('/ticket-receive');
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'ticket_assign') {
      try {
        const response = await dashboardApi.post('ticket-notification-seen-status', {
          emp_id: emp_id,
          type: 'ticket_assign',
        });
        
        if (response.data.status === 200) {
          navigate('/ticket-assign');
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'ticket_status') {
      try {
        const response = await dashboardApi.post('ticket-notification-seen-status', {
          emp_id: emp_id,
          type:'ticket_status',
        });
        
        if (response.data.status == 200) {
          navigate('/view-ticket-status/' + ticket_id);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'ticket_time_change') {
      try {
        const response = await dashboardApi.post('ticket-notification-seen-status', {
          emp_id: emp_id,
          type:'ticket_time_change',
        });

        if (response.data.status === 200) {
          navigate('/new-time-request-approval');
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'ticket_time_approval') {
      try {
        const response = await dashboardApi.post('ticket-notification-seen-status', {
          emp_id: emp_id,
          type:'ticket_time_approval',
        });
        if (response.data.status === 200) {
          navigate('/view-ticket-status/' + ticket_id);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'action_time_check') {
      try {
        const response = await dashboardApi.post('ticket-notification-seen-status', {
          emp_id: emp_id,
          type:'action_time_check',
        });

        if (response.data.status === 200) {
          navigate('/org-ticket-list');
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'missing_followup') {
      try {
        const endpoint = `update-missing-followup-seen-status/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          navigate('/missing_followup');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'lead verification Time') {
      try {
        const endpoint = `lead-verification-status-seen-status/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          navigate('/cs-enquiry-details');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (type === 'missing_kra_kpi') {
      try {
        const endpoint = `kra-kpi-seen-status/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          navigate('/missing-kra-kpi');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const fetchLiveNotification = async () => {
    try {
      const endpoint = `show-emp-notification/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.data !== null && response.data.status === 200) {
        toast.dismiss();

        const currentNotification = response.data.data.notification;
        if (showToast) {
          toast.success(
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{currentNotification}</span>
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              style: { background: 'green', color: 'white', width: '700px' },
              closeButton: false,
              onClick: () => handleViewClick(response.data.data.type, response.data.data.ticket_id),
            }
          );
          setShowToast(false);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <ToastContainer closeButton={false} />
    </>
  );
};

export default ShowEmpNotification;
