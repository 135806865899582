import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from 'sweetalert2';


const Enquiry_escalation_data = () => {
  const navigate = useNavigate();

  const { sales } = reportsLineChartData;
  const {total_enquiry} = useParams();
  const token = secureLocalStorage.getItem('token');
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const desi_id = secureLocalStorage.getItem('desi_id');
  const [product, setProduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setService] = useState('');
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [grouplist, setGroupList] = useState([]);
  const [group, setGroup] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  const [openModal,setopenModal] = useState(false);
  const[remark,setremark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);


  //  const getData_filter = async (page) => {
  //   try {
  //     const response = await dashboardApi.get('dm-dashboard-enquiry-data', {
  //       params: {
  //         per_page: postsPerPage,
  //         page: page + 1, 
  //         product:product,
  //         service:service,
  //         start_date:start_date,
  //         end_date:end_date,
  //         group:group,
  //         source_type:selectedsource_type,
  //         enq_status:selected_enqstatus,
  //         verified_status:verified_status,
  //         type:total_enquiry,
  //         category:category,
  //       }
  //     });

  //     if (response.data.status === 200) {
  //       const enqData = response.data.data.data || response.data.data;
  //       setList(enqData);
  //       setTotalPages(response.data.data.last_page);
  //     } else {
  //       console.error('Error fetching data:', response.data.error);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('get-enquiry-history-details', {
        params: {
          per_page: postsPerPage,
          page: currentPage + 1,
          emp_id:empId
        }
      });

      if (response.data.status === 200) {
        setList(response.data.data.data || response.data.data);
        setTotalPages(response.data.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    console.log(selected);
    setCurrentPage(selected.selected);
  };

return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
      <Link to='/sales-dashboard' className='btn btn-info btn-sm'>Back</Link>
        <Card>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <td style={{fontSize:'12px'}}>Group</td>
                       <td style={{fontSize:'12px'}}>Status</td>
                      <td style={{fontSize:'12px'}}>No Of Enquiry</td>
                      <td style={{fontSize:'12px'}}>Expected Amount</td>
                      <td style={{fontSize:'12px'}}>Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((row, index) => (
                      <tr key={row.id}>
                        <td style={{fontSize:'12px'}}>{row.group_name}</td>
                        <td style={{fontSize:'12px'}}>{row.name}</td>
                        <td style={{fontSize:'12px'}}>{row.enquiry_count}</td>
                        <td style={{fontSize:'12px'}}>{row.total_expected_value}</td>
                        <td style={{fontSize:'12px'}}>{row.created_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {list.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default Enquiry_escalation_data;
