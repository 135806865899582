import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import { Spinner } from 'react-bootstrap'; // Import Spinner

const DueAmount = () => {
  const navigate = useNavigate();
  const { sales, tasks } = reportsLineChartData;
  const empId = secureLocalStorage.getItem('emp_id');
  const [loading, setLoading] = useState(true);
  const [product, setproduct] = useState('');
  const [productlist, setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam, getmanagersteam] = useState([]);
  const [selectedemp, setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status, get_followup_details] = useState([]);
  const { details } = useParams();
  const [inner_page_details, get_inner_page_details] = useState([]);
  const [sum, get_sum] = useState();
  const [manager_team, get_manager_team] = useState([]);
  const [checkmanager, setcheckmanager] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  const postsPerPage = 10;

  useEffect(() => {
    productdetails();
    get_emp_list();
    get_sales_manager_team();
    groupdetails();
  }, []);

  useEffect(() => {
    sales_inner_page_details(currentPage);
  }, [currentPage,category, product, end_date, start_date, service, selectedmanagers, selectedemp, group]);

  useEffect(() => {
    sales_inner_page_filter(currentPage);
  }, [currentPage,category, product, end_date, start_date, service, selectedmanagers, selectedemp, group, currentPage]);

  const downloadata = async () => {
    try {
      const response = await dashboardApi.get('sales-inner-page-details', {
        params: {
          download: 'csv',
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          manager: selectedmanagers,
          employee: selectedemp,
          type: details,
          group: group,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `sales_data_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };

  const get_sales_manager_team = async () => {
    try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const groupdetails = async () => {
    try {
      const endpoint = `get-group-based-on-sales-emp/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);

  const sales_inner_page_details = async (page = 0) => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('due-amount', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          category:category,
          manager: selectedmanagers,
          employee: selectedemp,
          type: details,
          group: group,
          page: currentPage + 1,
        },
      });
      if (response.data.status === 200) {
        get_inner_page_details(response.data.data.data || response.data.data);
        setTotalPages(response.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const sales_inner_page_filter = async () => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('due-amount', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          category:category,
          manager: selectedmanagers,
          employee: selectedemp,
          type: details,
          group: group,
          page: currentPage + 1,
        },
      });
      if (response.data.status === 200) {
        get_inner_page_details(response.data.data.data || response.data.data);
        setTotalPages(response.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const handlePageClick = (selected) => {
    //console.log(selected);
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
           <Link to='/sales-dashboard' className='btn btn-info btn-sm'>Back</Link>
            <div className="d-flex flex-wrap">
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={group}
                  onChange={(e) => setgroup(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Group</option>
                  {grouplist.map((row) => (
                    <option key={row.group_id} value={row.group_id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Product</option>
                  {productlist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={service}
                  onChange={(e) => setservice(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Service</option>
                  {servicelist.map((ser) => (
                    <option key={ser.id} value={ser.id}>
                      {ser.service_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>

              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={start_date}
                  onChange={(e) => setstart_date(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={end_date}
                  onChange={(e) => setend_date(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              {empId === 'RIMS1' && (
                <>
                  <div className="col-sm-3 col-6 p-1">
                    <select
                      className="form-control form-select"
                      value={selectedmanagers}
                      onChange={(e) => setselectedmanagers(e.target.value)}
                      aria-label="Default select example"
                      style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                    >
                      <option>Select Managers</option>
                      {emp_list.map((row) => (
                        <option key={row.emp_id} value={row.emp_id}>
                          {row.emp_fname} {row.emp_lame}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-3 col-6 p-1">
                    <select
                      className="form-control form-select"
                      value={selectedemp}
                      onChange={(e) => setselectedemp(e.target.value)}
                      aria-label="Default select example"
                      style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                    >
                      <option>Select Team</option>
                      {managersteam.map((row) => (
                        <option key={row.emp_id} value={row.emp_id}>
                          {row.emp_fname} {row.emp_lame}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

              {checkmanager === 1 && (
                <div className="col-sm-3 col-6 p-1">
                  <select
                    className="form-control form-select"
                    value={selectedemp}
                    onChange={(e) => setselectedemp(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                  >
                    <option>Select Team</option>
                    {manager_team.map((row) => (
                      <option key={row.emp_id} value={row.emp_id}>
                        {row.emp_fname} {row.emp_lame}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="card-body">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" />
                <span>Loading....</span>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <td style={{ fontSize: '12px',fontWeight:'bold'}}>Group</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Product</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Service</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Category</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Package Name</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Client Name</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Company</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Paid Amount</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Due Amount</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Due Date</td>

                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Package Type</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Emp Name</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Manager</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Created Date</td>
                      <td style={{ fontSize: '12px',fontWeight:'bold' }}>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {inner_page_details.map((row, index) => (
                      <tr key={row.id}>
                        <td style={{ fontSize: '12px' }}>{row.group}</td>
                        <td style={{ fontSize: '12px' }}>{row.product}</td>
                        <td style={{ fontSize: '12px' }}>{row.service}</td>
                        <td style={{ fontSize: '12px' }}>{row.category}</td>
                        <td style={{ fontSize: '12px' }}>{row.pkg_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.client_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.company}</td>
                        <td style={{ fontSize: '12px' }}>{row.paid_amount}</td>
                        <td style={{ fontSize: '12px' }}>{row.amount}</td>
                        <td style={{ fontSize: '12px' }}>{row.due_date}</td>
                        <td style={{ fontSize: '12px' }}>{row.pkg_type}</td>
                        <td style={{ fontSize: '12px' }}>{row.emp_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.manager_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.created_date}</td>
                       <Link target='_blank' to={`/mature-client-profile/${row.client_id}`} className='btn btn-info btn-sm'>Profile</Link>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {inner_page_details.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default DueAmount;
