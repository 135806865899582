import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate,useParams } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

function Guest_client_details() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModal,setAddModal]=useState(false);
  const [clientMobile,setClientMobile] = useState("");
  const [clientName,setClientName] = useState('');
  const [clientBusinessName,setClientBusinessName] = useState('');
  const [clientEmail,setClientEmail] = useState('');
  const [clientCity,setClientCity] = useState('');
  
  const [clientAddress,setClientAddress] = useState();
  const [clientInfo,setClientInfo] = useState();
  const [isDivShow,setDivShow] = useState(false);
  const [cityList,setCityList] = useState([]);
  const [selectedCity,setSelectedCity] = useState();

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState();
  const [checkexists,setcheckexists] = useState();

  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState();
    
  const [category,setCategory]=useState([]);
  const [selectedCategory,setSelectedCategory] = useState();

  const [selectedCallStatus,setCallStatus] = useState(''); 
  
  const [followup,setFollowup] = useState([]);

  const [selectedFollowupStatus,setFollowupStatus] = useState('');

  const [nextDays,setNextDays] = useState(0);
  
  const [nextFollowupDate, setNextFollowupDate] = useState('');
  const [remarks , setRemarks] = useState('');
  //const [amount,setClientAmount] = useState('');
 
  const [clientDetails, setClientDetails] = useState([]);
  
  const myDate = new Date(nextFollowupDate);
  const nxtFolDate= myDate.toLocaleString();

  const [follwoupModal,setFollowupModal] = useState(false);
  const [selectedFollowCallStatus,setFollCallStatus] = useState(''); 
  const [selectedFollowStatus,setFollStatus] = useState('');
  const [followupRemarks , setFollowupRemarks] = useState('');
  //const [followupAmount,setFollowupAmount] = useState('');
  const [nxtFollowupDate, setNxtFollowupDate] = useState('');
  const [nextDays1,setNextDays1] = useState(0);
  const myDate1 = new Date(nxtFollowupDate);
  const nxtFolDate1 = myDate.toLocaleString();
  const [selectedClientId,setClientId] = useState();

  const [prePackageDetails,setPackageList] = useState([]);
  const [selectedPackage,setSelectedPackage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const {details} = useParams();
  const {id}      = useParams();
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [selectedMobileNo,setClientMobileNumber]=useState('');
  const [selectedCompanyName,setClientCompanyName]=useState('');

  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');


  const [editModal,setEditModal]=useState(false);
  const closeUpdateControl=()=>
  {
    setEditModal(false);
  }
  const [editClientId,setEditClientId]=useState('');
  

  const updateClientDetails = async()=>
  {
      const errors = {};
      const formData = new FormData();
      formData.append('mobile',clientMobile);
      formData.append('name',clientName);
      formData.append('business_name',clientBusinessName);
      formData.append('email',clientEmail);
      formData.append('group',selectedCity);
      formData.append('city',clientCity);
      formData.append('address',clientAddress);
      formData.append('product_id',selectedProduct);
      formData.append('service_id',selectedService);
      formData.append('category_id',selectedCategory);
      formData.append('editClientId',editClientId);
      formData.append('created_by',emp_id);
      
      if(!clientName)
      {
        errors.clientName = 'Name is required';
      }
      if(!clientBusinessName)
      {
        errors.clientBusinessName = 'Business Name is required';
      }

      if(!clientMobile)
      {
        errors.clientMobile = 'Mobile Number is required';
      }

      if(!selectedCity)
      {
        errors.selectedCity = 'Client Group is required';
      }
      
      if(!clientCity)
      {
        errors.clientCity = 'Client city is required';
      }

      if(!clientAddress)
      {
        errors.clientAddress = 'Address is required';
      }

      if(!selectedProduct)
      {
        errors.selectedProduct = 'Product is required';
      }

      if(!selectedService)
      {
        errors.selectedService = 'Service is required';
      }
      setValidationErrors(errors);
      if(Object.keys(errors).length > 0)
      {
        return; 
      }
    
    try
    {
      const response = await dashboardApi.post('update-guest-client-details',formData);
      if(response.data.status === 200)
      {
        setEditModal(false);
        getClientDetails();
        resetFollowupFields();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else
      {
        setEditModal(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    }
    catch (error)
    {
      setEditModal(false);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
  }

  const editOpenModal = async(id) =>
  {
    setLoading(true);
    setEditClientId(id);
    try
    {
      const response = await dashboardApi.get('get-guest-client-details',{
        params:{
          emp_id:emp_id,
          id:id,
        }
      });
      if(response.data.status === 200)
      {
          // setDivShow(true);
          setEditModal(true);
          setClientMobile(response.data.data.mobile_no);
          setcheckexists('No');
          setClientName(response.data.data.name);
          setClientBusinessName(response.data.data.business_name)
          setClientEmail(response.data.data.email_id)
          setSelectedCity(response.data.data.group_id)
          setClientCity(response.data.data.city)
          setClientAddress(response.data.data.address)
          setSelectedProduct(response.data.data.product_id)
          setSelectedService(response.data.data.service_id)
       }
       else
       {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
    }
    catch (error)
    {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
  }



  

  const getExeDetails = async () => {
    try{
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }catch (error){
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const resetFollowupFields = ()=>
  {
    setClientMobile('');
    setClientName('');
    setClientBusinessName('');
    setClientEmail('');
    setClientAddress('');
    setSelectedProduct('');
    setSelectedCity('');
    setSelectedService('');
    setSelectedCategory('');
    setCallStatus('');
    setFollowupStatus('');
    setFollowupStatus('');
    setNextDays('');
    setRemarks('');
    setFollCallStatus('');
    setFollStatus('');
    setFollowupRemarks('');
    setNxtFollowupDate('');
    setSelectedPackage('');
  }

  console.log(selectedFollowStatus);
  //setClientType(props.name);
  const followupOpenControl=(id)=>{
    setClientId(id);
    setFollowupModal(true);
  }
  
  const closeFollowupControl = ()=>
  {
    setFollowupModal(false);
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  useEffect(() =>
  {
    if(id)
    {
      const fetchData = async () => {
      const formData = new FormData();
      formData.append('emp_id', emp_id);
      formData.append('id', id);
      
      try
      {
        const response = await dashboardApi.post('update-business-lead-data', formData);
        if(response.data.status === 200)
        {
            // setDivShow(true);
            setClientMobile(response.data.data.mobile_no);
            setcheckexists(response.data.exists);
            setClientName(response.data.data.name);
            setClientBusinessName(response.data.data.business_name)
            setClientEmail(response.data.data.email_id)
            setSelectedCity(response.data.data.group_id)
            setClientCity(response.data.data.city)
            setClientAddress(response.data.data.address)
            setSelectedProduct(response.data.data.product_id)
            setSelectedService(response.data.data.service_id)
         }
         else
         {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
      }
      catch (error)
      {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating',
        });
      }
    };
  
    if (details === 'business_lead') {
      setAddModal(true); 
      fetchData(); 
    }
  }
  }, [details, emp_id, id]);


  

   
      const getClientDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-client-details`,{
            params:{
              emp_id:emp_id,
              per_page: postPerPage,
              page: currentPage + 1,
            }
          });

          if(response.data.status === 200)
          {
            const responseData = response.data.data;
            const clientData = responseData.data || responseData;
            setNumber(responseData.last_page);
            setClientDetails(clientData);
            //setClientDetails(response.data.data);
          }else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }

      useEffect(()=>{
        if(selectedEmp || selectedMobileNo || selectedCompanyName || currentPage)
        {
          let employeeId='';
            if(selectedEmp ==''){
              employeeId = emp_id;
            }else{
              employeeId = selectedEmp;
            }
          const getFindClientDetails = async () => {
            try {
              const response = await dashboardApi.get(`get-client-details`,{
                params:{
                  emp_id:employeeId,
                  mobileNo:selectedMobileNo,
                  companyName:selectedCompanyName,
                  per_page: postPerPage,
                  page: currentPage + 1,
                }
              });
              if (response.data.status === 200) {
                const responseData = response.data.data;
                const clientData = responseData.data || responseData;
                setNumber(responseData.last_page);
                setClientDetails(clientData);
               
              } else {
                console.error('Error fetching countries:',response.data.message);
              }
              setLoading(false);
            } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
            }
          }
          getFindClientDetails();
      
      }},[selectedEmp,selectedMobileNo,selectedCompanyName,currentPage]);
    

  const submitFollowupForm = async() =>
  {
      const errors = {};
      if (Object.keys(errors).length > 0) {
        return; 
      }
      const formData = new FormData();
      formData.append('client_id',selectedClientId);
      formData.append('disposition',selectedFollowCallStatus);
      formData.append('followup_status',selectedFollowStatus);
      formData.append('next_followup_date',nxtFolDate1);
      formData.append('remarks',followupRemarks);
      formData.append('created_by',emp_id);
       
      try 
      {
          const response = await dashboardApi.post('add-client-followup', formData);
            if(response.data.status === 200)
            {
                resetFollowupFields();  
                //navigate("/guest-client-details");
                closeModalOpenControl();
                Swal.fire({
                icon: 'success',
                text: response.data.message,
                });
              
             
            }
            else
            {
              closeModalOpenControl();
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        closeModalOpenControl();
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  }


  
  const submitForm = async() =>
  {
      const errors = {};
      const formData = new FormData();
      formData.append('mobile',clientMobile);
      formData.append('name',clientName);
      formData.append('business_name',clientBusinessName);
      formData.append('email',clientEmail);
      formData.append('group',selectedCity);
      formData.append('city',clientCity);
      formData.append('address',clientAddress);
      formData.append('product_id',selectedProduct);
      formData.append('service_id',selectedService);
      formData.append('category_id',selectedCategory);
      formData.append('disposition',selectedCallStatus);
      formData.append('followup_status',selectedFollowupStatus);
      formData.append('next_followup_date',nxtFolDate);
      formData.append('remarks',remarks);
      formData.append('created_by',emp_id);
      formData.append('prePackageId',selectedPackage); 
      if(!clientName)
      {
        errors.clientName = 'Name is required';
      }
      if(!clientBusinessName)
      {
        errors.clientBusinessName = 'Business Name is required';
      }

      if(!clientMobile)
      {
        errors.clientMobile = 'Mobile Number is required';
      }

      if(!selectedCity)
      {
        errors.selectedCity = 'Client Group is required';
      }
      
      if(!clientCity)
      {
        errors.clientCity = 'Client city is required';
      }

      if(!clientAddress)
      {
        errors.clientAddress = 'Address is required';
      }

      if(!selectedProduct)
      {
        errors.selectedProduct = 'Product is required';
      }

      if(!selectedService)
      {
        errors.selectedService = 'Service is required';
      }

      
      if(!selectedCallStatus)
      {
        errors.selectedCallStatus = 'Call status is required';
      }

      if(!selectedFollowupStatus)
      {
        errors.selectedFollowupStatus = 'Followup status is required';
      }

      if(!nextFollowupDate && selectedCallStatus=='Connected')
      {
        errors.nextFollowupDate = 'Next followup date is required';
      }


      if(!remarks)
      {
        errors.remarks = 'Remarks is required';
      }
      
      if(selectedFollowupStatus ==5 | selectedFollowupStatus ==6 | selectedFollowupStatus ==7 | selectedFollowupStatus == 9)
      {
        if(!selectedPackage)
        {
          errors.selectedPackage = 'Package is required';
        }
      }
      
      setValidationErrors(errors);
     
      if(Object.keys(errors).length > 0)
      {
        return; 
      }
      
      try 
      {
          const response = await dashboardApi.post('add-guest-client', formData);
            if(response.data.status === 200)
            {
                
                closeModalOpenControl(false);
                resetFollowupFields();
                getClientDetails();
                Swal.fire({
                  icon: 'success',
                  text: response.data.message,
                  });
            }
            else
            {
              closeModalOpenControl(false);
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'The email must be a valid email address or check required field.',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  }

    useEffect(() => {
      if (!token) {
        navigate("/sign-in");
      }
    }, [token, navigate]);

    useEffect(()=>{
      checkClientsDetails();
      getCityDetails();
      getProduct();
      getClientDetails();
      getExeDetails();
    },[])

    const addModalOpenControl=()=>{
      setAddModal(true);
    }

    const closeModalOpenControl = ()=>
    {
      setAddModal(false);
    }
      
    const checkClientsDetails = async () =>
    {
      const errors = {};
      
      if(!clientMobile)
      {
        errors.chkmobileNo = 'Mobile no in required.';
      }
      if(clientMobile.length != 10)
      {
        errors.chkmobileNo = 'Invalied mobile no.';
      }

      // if(clientMobile.length > 10)
      // {
      //   errors.chkmobileNo = 'Invalied mobile no.';
      // }
      setValidationErrors(errors);


      if (Object.keys(errors).length > 0) {
        return; 
      }
     
      if(clientMobile)
      {
        try {
          const response = await dashboardApi.get(`check-client-mobile-number/${clientMobile}`);
          if (response.data.status === 200)
          {
            setDivShow(false);
            setClientInfo(response.data.message);
          } 
          else
          {
            setDivShow(true);
            setClientInfo('');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
    }
  
    function checkMobileNumber()
    {
      checkClientsDetails();
    }

    const checkInput = (e) => {
      const number = e.target.value.replace(/\D/g, "");
      setClientMobile(number);
    };

    const getCityDetails= async()=>
    {
      try {
          const response = await dashboardApi.get(`get-city-details`);
          if (response.data.status === 200)
          {
            setCityList(response.data.data);
          } 
          else
          {
           console.log('Data not found');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
    }

    const getProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

    useEffect(()=>{
      if(selectedProduct)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
            if (response.data.status === 200) {
              setService(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedProduct]);

    useEffect(()=>{
      if(selectedProduct && selectedCity && selectedService)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-pre-package-list/${selectedProduct}/${selectedCity}/${selectedService}`);
            if (response.data.status === 200) {
              setPackageList(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedCity,selectedCity,selectedService]);
  
      useEffect(()=>{
          if(selectedService)
          {
          const fetchCategory = async () => {
            try {
              const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
              if (response.data.status === 200) {
                  setCategory(response.data.data);
              } else {
                  console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
              } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
              }
          }
          fetchCategory();
          }
      },[selectedService]);

      useEffect(()=>{
        if(selectedCallStatus)
        {
          

        const getFollowupStatus = async () => {
            try {
            const response = await dashboardApi.get(`get-followup-list/${selectedCallStatus}`);
            if (response.data.status === 200) {
              setFollowup(response.data.data);
             } else {
                console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
            } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
            }
        }
        getFollowupStatus();
        }
    },[selectedCallStatus]);

    useEffect(()=>{
      if(selectedFollowCallStatus)
      {
        

      const getFollowupStatus = async () => {
          try {
          const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
          if (response.data.status === 200)
          {
            setFollowup(response.data.data);
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
      }
      getFollowupStatus();
      }
  },[selectedFollowCallStatus]);
  

    useEffect(()=>{
      if(selectedFollowupStatus)
      {
      const getNextFollowupDate = async () => {
          try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowupStatus}`);
          if (response.data.status === 200) {
            setNextFollowupDate('');
            setNextDays(response.data.data);
            
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
          } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
          }
      }
      getNextFollowupDate();
      }
  },[selectedFollowupStatus]);

  useEffect(()=>{
    if(selectedFollowStatus)
    {
    const getNextFollowupDate = async () => {
        try {
        const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowStatus}`);
        if (response.data.status === 200) {
          setNextFollowupDate('');
          setNextDays1(response.data.data);
          
        } else {
            console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
        } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
        }
    }
    getNextFollowupDate();
    }
},[selectedFollowStatus]);


const updateBusinessLeadData = async ()=>{
  const errors = {};
  const formData = new FormData();
  formData.append('mobile',clientMobile);
  formData.append('name',clientName);
  formData.append('business_name',clientBusinessName);
  formData.append('email',clientEmail);
  formData.append('group',selectedCity);
  formData.append('city',clientCity);
  formData.append('address',clientAddress);
  formData.append('product_id',selectedProduct);
  formData.append('service_id',selectedService);
  formData.append('category_id',selectedCategory);
  formData.append('disposition',selectedCallStatus);
  formData.append('followup_status',selectedFollowupStatus);
  formData.append('next_followup_date',nxtFolDate);
  formData.append('remarks',remarks);
  formData.append('created_by',emp_id);
  formData.append('prePackageId',selectedPackage); 
  formData.append('id',id);
  if(!clientName)
  {
    errors.clientName = 'Name is required';
  }
  if(!clientBusinessName)
  {
    errors.clientBusinessName = 'Business Name is required';
  }

  if(!clientMobile)
  {
    errors.clientMobile = 'Mobile Number is required';
  }

  if(!selectedCity)
  {
    errors.selectedCity = 'Client Group is required';
  }
  
  if(!clientCity)
  {
    errors.clientCity = 'Client city is required';
  }

  if(!clientAddress)
  {
    errors.clientAddress = 'Address is required';
  }

  if(!selectedProduct)
  {
    errors.selectedProduct = 'Product is required';
  }

  if(!selectedService)
  {
    errors.selectedService = 'Service is required';
  }

  
  if(!selectedCallStatus)
  {
    errors.selectedCallStatus = 'Call status is required';
  }

  if(!selectedFollowupStatus)
  {
    errors.selectedFollowupStatus = 'Followup status is required';
  }

  if(!nextFollowupDate && selectedCallStatus=='Connected')
  {
    errors.nextFollowupDate = 'Next followup date is required';
  }


  if(!remarks)
  {
    errors.remarks = 'Remarks is required';
  }

  if(selectedFollowupStatus ==5 && selectedFollowupStatus ==6 && selectedFollowupStatus ==7 && selectedFollowupStatus == 9)
  {
    errors.selectedPackage = 'Package is required';
  }
  
  setValidationErrors(errors);
 
  if(Object.keys(errors).length > 0)
  {
    return; 
  }
  
  try 
  {
      const response = await dashboardApi.post('update-guest-client-details', formData);
        if(response.data.status === 200)
        {
            
            closeModalOpenControl();
            resetFollowupFields();
            navigate('/guest-client-details');
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              });
        }
        else
        {
          Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
          });
        }
  }catch (error){
    Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
    });
  } 
}


  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <ClientDetailsHeader></ClientDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={addModalOpenControl}>
                          +
                      </Link>
                      </div>
                    </div>
                      {/* <div className='row'>
                        <div className='col-sm-3'>
                          <label>Enter Mobile No</label>
                          <input type="text" className='form-control' value={selectedMobileNo} onChange={(e)=>{setClientMobileNumber(e.target.value)}}/>
                        </div>
                        <div className='col-sm-3'>
                            <label>Select Eexecutive</label>
                            <select className="form-control"
                              value={selectedEmp}
                              onChange={(e) => setSelectedEmp(e.target.value)}
                              >
                              <option value=''>Select Eexecutive</option>
                              {exeDetails.map((ed) => (
                              <option key={ed.emp_id} value={ed.emp_id}>
                              {ed.emp_fname} {ed.emp_lname}
                              </option>
                              ))}
                            </select>
                        </div>
                      </div> */}
                       <div className='row'>
                      <div className='col-sm-2'>
                        <label>Mobile No</label>
                        <input type="text" className='form-control' value={selectedMobileNo} onChange={(e)=>{setClientMobileNumber(e.target.value)}}/>
                      </div>
                      <div className='col-sm-3'>
                        <label>Company Name</label>
                        <input type="text" className='form-control' value={selectedCompanyName} onChange={(e)=>{setClientCompanyName(e.target.value)}}/>
                      </div>
                      <div className='col-sm-2'>
                        <label>Select Eexecutive</label>
                        <select className="form-control"
                          value={selectedEmp}
                          onChange={(e) => setSelectedEmp(e.target.value)}
                          >
                          <option value=''>Select Eexecutive</option>
                          {exeDetails.map((ed) => (
                          <option key={ed.emp_id} value={ed.emp_id}>
                          {ed.emp_fname} {ed.emp_lname}
                          </option>
                          ))}
                        </select>
                    </div>
                  </div>
                  

                  {loading  && !editClientId ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                ) : (
                  <div style={{overflowY: "hidden"}}>
                    
                    <table className="table table-bordered table-hovered" style = {{marginTop:'20px'}}>
                    
                      <tr>
                        <td>S.No</td>
                        <td>Client Info</td>
                        <td>Contact Info</td>
                        <td>Product</td>
                        <td>Status</td>
                        <td>Action</td>
                      </tr>

                      {clientDetails && clientDetails.map((cd,index)=>(
                        <tr>
                            <td>{index + 1}</td>
                            <td>{cd.name} <br />{cd.business_name}</td>
                            <td>{cd.mobile_no} <br/> {cd.email_id}</td>
                            
                            <td>{cd.product_name}</td>
                            <td>{cd.status === 1 ?'Active':'Deactive'}</td>
                            <td>
                              <Link className="btn btn-primary mb-2 float-end"  to={`/client-profile/${cd.id}`}>
                              <span class="material-icons-outlined" alt='Profile'>manage_accounts</span>
                              </Link>
                              &nbsp; &nbsp; &nbsp;
                              {loading && editClientId == cd.id?(
                                
                                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'70px',width:'100px'}}></img>
                                 
                                ) : (
                                <span className="material-icons-outlined btn btn-primary mb-2 float-end" onClick={()=>{editOpenModal(cd.id)}}>
                                edit_note
                                </span>
                              )}
                            </td>
                        </tr>
                        ))
                      }
                    <tr>
                      <td colspan="6">
                        <Pagination className="custom-pagination">
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                          />
                        </Pagination>
                      </td>
                    </tr>
                  </table>
                </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      
      

      <Dialog className='col-sm-10 col-11 p-sm-4 p-3 mx-auto' open={addModal}>
        <DialogTitle>Add Client Details</DialogTitle>
            <DialogContent style={{margin: '0'}}>
                <form>
                <div className="row">
                  <div className="col-sm-6">
                      <label>Check Mobile No</label>
                      <TextField type="text"  maxLength={10}  className='form-control' value={clientMobile} onChange={(e) =>checkInput(e)} ></TextField>
                      <p style={{color:'red'}}>{clientInfo}</p>

                      { !details && validationErrors.chkmobileNo && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.chkmobileNo}</div>
                      )}
                      {
                      checkexists == 'Yes' && details && 
                      <p style={{color:'red'}}>This Number Is Match With Another Record</p>
                     }
                  </div>

                  {!details && 
                  <div className="col-sm-6">
                      <Button style={{marginTop:'35px',float:'right'}} onClick={checkMobileNumber}>Check Client Details</Button>
                  </div>
                  }
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Basic Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                </div>


                {isDivShow && (
                <div className="row">
                  <div class="col-sm-6">
                      <label>Client Name</label>
                      <TextField type="text" className='form-control' value={clientName} onChange={(e)=>setClientName(e.target.value)}></TextField>
                      {validationErrors.clientName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Business Name</label>
                      <TextField type="text" className='form-control' value={clientBusinessName} onChange={(e)=>setClientBusinessName(e.target.value)}></TextField>
                      {validationErrors.clientBusinessName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientBusinessName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Mobile Number</label>
                      <TextField type="text" className='form-control' value={clientMobile} readOnly></TextField>
                      {validationErrors.clientMobile && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Email Id</label>
                      <TextField type="text" className='form-control' value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)}></TextField>

                  </div>

                  <div class="col-sm-6">
                    <label>Select Group</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                    {validationErrors.selectedCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCity}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>City</label>
                      <TextField type="text" className='form-control' value={clientCity} onChange={(e)=>setClientCity(e.target.value)}></TextField>
                      {validationErrors.clientCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                      )}
                  </div>

                  <div class="col-sm-12">
                      <label>Address</label>
                      <TextField type="text" className='form-control' value={clientAddress} onChange={(e)=>setClientAddress(e.target.value)}></TextField>
                      {validationErrors.clientAddress && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                      )}
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Product Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    {validationErrors.selectedProduct && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                      )}
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                      {validationErrors.selectedService && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                      )}
                  </div>
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                      
                  </div>
                )}

                {selectedProduct && selectedService &&(
               
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                )}

                {selectedProduct && selectedService &&(
                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedCallStatus}
                          onChange={(e) => setCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                        </select>
                        {validationErrors.selectedCallStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCallStatus}</div>
                      )}
                  </div>
                )}

                {selectedCallStatus  &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowupStatus}
                          onChange={(e) => setFollowupStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedFollowupStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedFollowupStatus}</div>
                        )}
                  </div>
                )}

                {selectedCity && selectedProduct && selectedService && (selectedFollowupStatus ==5 || selectedFollowupStatus ==6 || selectedFollowupStatus ==7 || selectedFollowupStatus ==9) &&(
                  <div className="col-sm-12">
                      <label>Select Package</label>
                        <select className="form-control"
                          value={selectedPackage}
                          onChange={(e) => setSelectedPackage(e.target.value)}
                          >
                          <option value=''>Select Package Details</option>
                          {prePackageDetails.map((prePkg) => (
                          <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedPackage && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackage}</div>
                        )}
                  </div>
                )}
                
                {selectedFollowupStatus && selectedCallStatus !='Not_Connected' && selectedFollowupStatus !=15 &&(
                  
                  <div className="col-sm-6">
                      <label>Next Followup Date</label>
                      <DatePicker className="form-control"
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nextFollowupDate}
                        onChange={(date) => setNextFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                       }}
                      />
                      {validationErrors.nextFollowupDate && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.nextFollowupDate}</div>
                        )}
                    </div>
                )}

                {selectedFollowupStatus &&(
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></TextField>
                    {validationErrors.remarks && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remarks}</div>
                    )}
                  </div>
                )}
                </div>
              )}

                {checkexists=='No'  && (
                <div className="row">
                  <div class="col-sm-6">
                      <label>Client Name</label>
                      <TextField type="text" className='form-control' value={clientName} onChange={(e)=>setClientName(e.target.value)}></TextField>
                      {validationErrors.clientName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Business Name</label>
                      <TextField type="text" className='form-control' value={clientBusinessName} onChange={(e)=>setClientBusinessName(e.target.value)}></TextField>
                      {validationErrors.clientBusinessName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientBusinessName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Mobile Number</label>
                      <TextField type="text" className='form-control' value={clientMobile} readOnly></TextField>
                      {validationErrors.clientMobile && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Email Id</label>
                      <TextField type="text" className='form-control' value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)}></TextField>

                  </div>

                  <div class="col-sm-6">
                    <label>Select Group</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                    {validationErrors.selectedCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCity}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>City</label>
                      <TextField type="text" className='form-control' value={clientCity} onChange={(e)=>setClientCity(e.target.value)}></TextField>
                      {validationErrors.clientCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                      )}
                  </div>

                  <div class="col-sm-12">
                      <label>Address</label>
                      <TextField type="text" className='form-control' value={clientAddress} onChange={(e)=>setClientAddress(e.target.value)}></TextField>
                      {validationErrors.clientAddress && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                      )}
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Product Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    {validationErrors.selectedProduct && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                      )}
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                      {validationErrors.selectedService && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                      )}
                  </div>
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                      
                  </div>
                )}

                {selectedProduct && selectedService &&(
               
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                )}

                {selectedProduct && selectedService &&(
                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedCallStatus}
                          onChange={(e) => setCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                        </select>
                        {validationErrors.selectedCallStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCallStatus}</div>
                      )}
                  </div>
                )}

                {selectedCallStatus  &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowupStatus}
                          onChange={(e) => setFollowupStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedFollowupStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedFollowupStatus}</div>
                        )}
                  </div>
                )}

                {selectedCity && selectedProduct && selectedService && (selectedFollowupStatus ==5 || selectedFollowupStatus ==6 || selectedFollowupStatus ==7 || selectedFollowupStatus ==9) &&(
                  <div className="col-sm-12">
                      <label>Select Package</label>
                        <select className="form-control"
                          value={selectedPackage}
                          onChange={(e) => setSelectedPackage(e.target.value)}
                          >
                          <option value=''>Select Package Details</option>
                          {prePackageDetails.map((prePkg) => (
                          <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedPackage && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackage}</div>
                        )}
                  </div>
                )}
                
                {selectedFollowupStatus && selectedCallStatus !='Not_Connected' && selectedFollowupStatus !=15 &&(
                  
                  <div className="col-sm-6">
                      <label>Next Followup Date</label>
                      <DatePicker className="form-control"
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nextFollowupDate}
                        onChange={(date) => setNextFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                       }}
                      />
                      {validationErrors.nextFollowupDate && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.nextFollowupDate}</div>
                        )}
                    </div>
                )}

                {selectedFollowupStatus &&(
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></TextField>
                    {validationErrors.remarks && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remarks}</div>
                    )}
                  </div>
                )}
                </div>
              )}
              </form>
            </DialogContent>

            <DialogActions>
                <Link className="btn btn-primary btn-sm" onClick={closeModalOpenControl}>Close</Link>
                {isDivShow && (
                <Link className="btn btn-success btn-sm" onClick={submitForm}>Add Details</Link>
                )}
                {
                  checkexists =='No' && details && (
                  <Link className="btn btn-success btn-sm" onClick={updateBusinessLeadData}>Update</Link>
                )}
            </DialogActions>
      </Dialog>

      <Dialog className='col-sm-8 col-11 p-sm-4 p-3 mx-auto' open={editModal}>
        <DialogTitle>Update Client Details</DialogTitle>
            <DialogContent style={{margin: '0'}}>
                <form>
                <div className="row">
                  <div className="col-sm-6">
                      <label>Check Mobile No</label>
                      <TextField type="text"  maxLength={10}  className='form-control' value={clientMobile} onChange={(e) =>checkInput(e)} ></TextField>
                      <p style={{color:'red'}}>{clientInfo}</p>

                      { !details && validationErrors.chkmobileNo && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.chkmobileNo}</div>
                      )}
                  </div>

                  {!details && 
                  <div className="col-sm-6">
                      <Button style={{marginTop:'35px',float:'right'}} onClick={checkMobileNumber}>Check Client Details</Button>
                  </div>
                  }
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Basic Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                </div>


                {isDivShow && (
                <div className="row">
                  <div class="col-sm-6">
                      <label>Client Name</label>
                      <TextField type="text" className='form-control' value={clientName} onChange={(e)=>setClientName(e.target.value)}></TextField>
                      {validationErrors.clientName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Business Name</label>
                      <TextField type="text" className='form-control' value={clientBusinessName} onChange={(e)=>setClientBusinessName(e.target.value)}></TextField>
                      {validationErrors.clientBusinessName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientBusinessName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Mobile Number</label>
                      <TextField type="text" className='form-control' value={clientMobile} readOnly></TextField>
                      {validationErrors.clientMobile && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Email Id</label>
                      <TextField type="text" className='form-control' value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)}></TextField>

                  </div>

                  <div class="col-sm-6">
                    <label>Select Group</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                    {validationErrors.selectedCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCity}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>City</label>
                      <TextField type="text" className='form-control' value={clientCity} onChange={(e)=>setClientCity(e.target.value)}></TextField>
                      {validationErrors.clientCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                      )}
                  </div>

                  <div class="col-sm-12">
                      <label>Address</label>
                      <TextField type="text" className='form-control' value={clientAddress} onChange={(e)=>setClientAddress(e.target.value)}></TextField>
                      {validationErrors.clientAddress && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                      )}
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Product Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    {validationErrors.selectedProduct && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                      )}
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                      {validationErrors.selectedService && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                      )}
                  </div>
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                  </div>
                )}

                {selectedProduct && selectedService &&(
               
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                )}

                {selectedProduct && selectedService &&(
                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedCallStatus}
                          onChange={(e) => setCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                        </select>
                        {validationErrors.selectedCallStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCallStatus}</div>
                      )}
                  </div>
                )}

                {selectedCallStatus  &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowupStatus}
                          onChange={(e) => setFollowupStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedFollowupStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedFollowupStatus}</div>
                        )}
                  </div>
                )}

                {selectedCity && selectedProduct && selectedService && (selectedFollowupStatus ==5 || selectedFollowupStatus ==6 || selectedFollowupStatus ==7 || selectedFollowupStatus ==9) &&(
                  <div className="col-sm-12">
                      <label>Select Package</label>
                        <select className="form-control"
                          value={selectedPackage}
                          onChange={(e) => setSelectedPackage(e.target.value)}
                          >
                          <option value=''>Select Package Details</option>
                          {prePackageDetails.map((prePkg) => (
                          <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedPackage && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackage}</div>
                        )}
                  </div>
                )}
                
                {selectedFollowupStatus && selectedCallStatus !='Not_Connected' && selectedFollowupStatus !=15 &&(
                  
                  <div className="col-sm-6">
                      <label>Next Followup Date</label>
                      <DatePicker className="form-control"
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nextFollowupDate}
                        onChange={(date) => setNextFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                       }}
                      />
                      {validationErrors.nextFollowupDate && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.nextFollowupDate}</div>
                        )}
                    </div>
                )}

                {selectedFollowupStatus &&(
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></TextField>
                    {validationErrors.remarks && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remarks}</div>
                    )}
                  </div>
                )}
                </div>
              )}

                {checkexists=='No'  && (
                <div className="row">
                  <div class="col-sm-6">
                      <label>Client Name</label>
                      <TextField type="text" className='form-control' value={clientName} onChange={(e)=>setClientName(e.target.value)}></TextField>
                      {validationErrors.clientName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Business Name</label>
                      <TextField type="text" className='form-control' value={clientBusinessName} onChange={(e)=>setClientBusinessName(e.target.value)}></TextField>
                      {validationErrors.clientBusinessName && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientBusinessName}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Mobile Number</label>
                      <TextField type="text" className='form-control' value={clientMobile} readOnly></TextField>
                      {validationErrors.clientMobile && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>Email Id</label>
                      <TextField type="text" className='form-control' value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)}></TextField>

                  </div>

                  <div class="col-sm-6">
                    <label>Select Group</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                    {validationErrors.selectedCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCity}</div>
                      )}
                  </div>

                  <div class="col-sm-6">
                      <label>City</label>
                      <TextField type="text" className='form-control' value={clientCity} onChange={(e)=>setClientCity(e.target.value)}></TextField>
                      {validationErrors.clientCity && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                      )}
                  </div>

                  <div class="col-sm-12">
                      <label>Address</label>
                      <TextField type="text" className='form-control' value={clientAddress} onChange={(e)=>setClientAddress(e.target.value)}></TextField>
                      {validationErrors.clientAddress && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                      )}
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Product Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    {validationErrors.selectedProduct && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                      )}
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                      {validationErrors.selectedService && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                      )}
                  </div>
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                      
                  </div>
                )}

               

                
                </div>
              )}
              </form>
            </DialogContent>

            <DialogActions>
                <Link className="btn btn-primary btn-sm" onClick={closeUpdateControl}>Close</Link>
                <Link className="btn btn-success btn-sm" onClick={updateClientDetails}>Update Details</Link>
            </DialogActions>
      </Dialog>


      {/* for already added client followup details */}
      
      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
      <DialogTitle>Client Followup</DialogTitle>
        <DialogContent style={{height:"350px"}}>
            <form>
              <div className="row">
                
                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Followup Details</p>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>
          
                <div className="col-sm-6">
                    <label>Call Status</label>
                    <select className="form-control"
                        value={selectedFollowCallStatus}
                        onChange={(e) => setFollCallStatus(e.target.value)}
                        >
                      <option value=''>Select Call Status</option>
                      <option value='Connected'>Connected</option>
                      <option value='Not_Connected'>Not Connected</option>
                    </select>
                </div>
          

                {selectedFollowCallStatus &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowStatus}
                          onChange={(e) => setFollStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                  </div>
                )}
              
              {selectedFollowStatus &&(
                <div className="col-sm-6">
                    <label>Next Followup Date</label>
                    <DatePicker className="form-control" style={{position: 'absolute'}}
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays1))}
                      selected={nxtFollowupDate}
                      onChange={(date) => setNxtFollowupDate(date)}
                      dateFormat="dd/MM/YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                </div>
              )}
            
              {selectedFollowStatus &&(
                <div className="col-sm-12">
                  <label>Remarks</label>
                  <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField> 
                </div>
              )}
              </div>
            
              
              </form>
            </DialogContent>

            <DialogActions>
                <Link className="btn btn-primary btn-sm" onClick={closeModalOpenControl}>Close</Link>
                {isDivShow && (
                <Link className="btn btn-success btn-sm" onClick={submitForm}>Add Details</Link>
                )}
            </DialogActions>
      </Dialog>


      {/* for already added client followup details */}
      
      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
      <DialogTitle>Client Followup</DialogTitle>
        <DialogContent style={{height:"350px"}}>
            <form>
              <div className="row">
                
                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Followup Details</p>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>
          
                <div className="col-sm-6">
                    <label>Call Status</label>
                    <select className="form-control"
                        value={selectedFollowCallStatus}
                        onChange={(e) => setFollCallStatus(e.target.value)}
                        >
                      <option value=''>Select Call Status</option>
                      <option value='Connected'>Connected</option>
                      <option value='Not_Connected'>Not Connected</option>
                    </select>
                </div>
          

                {selectedFollowCallStatus &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowStatus}
                          onChange={(e) => setFollStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                  </div>
                )}
              
              {selectedFollowStatus &&(
                <div className="col-sm-6">
                    <label>Next Followup Date</label>
                    <DatePicker className="form-control" style={{position: 'absolute'}}
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays1))}
                      selected={nxtFollowupDate}
                      onChange={(date) => setNxtFollowupDate(date)}
                      dateFormat="dd/MM/YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                </div>
              )}
            
              {selectedFollowStatus &&(
                <div className="col-sm-12">
                  <label>Remarks</label>
                  <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField> 
                </div>
              )}
              
              </div>

          </form>
        </DialogContent>

        <DialogActions>
            <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>
            <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Add Details</Link>
            {
              checkexists =='No' && details && (
              <Link className="btn btn-success btn-sm" onClick={updateBusinessLeadData}>Update</Link>

                )}
        </DialogActions>
    </Dialog>

    </DashboardLayout>
  );
}
export default Guest_client_details;
