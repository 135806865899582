import React, { useState, useEffect } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate ,Link } from 'react-router-dom';
import ReportsDetailsHeader from 'layouts/common_components/reports_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';

import axios from 'axios';
import { saveAs } from 'file-saver';

function Hourly_enquiry_report()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');

  const [loading, setLoading] = useState(true);
  const [enqReports, setEnqReports] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [selectFromDate, setFilterDateFrom] = useState('');
  const [selectToDate, setFilterDateTo] = useState('');
  const [dateError, setError] = useState('');

  const [viewEnqReport, setViewEnqReports] = useState([]);
  const [selectedDuration, setDuration] = useState('');

  
  

  useEffect(() => {
    getEenquiryReports();
  }, [currentPage])

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setError('');
      }
    }
  };

  const getEenquiryReports = async () => {
    setLoading(true);
    try {
      const response = await dashboardApi.get(`get-hourly-enquiry-reports`, {
        params: {
          emp_id: emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          per_page: postPerPage,
          
          page: currentPage + 1, 
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const exeData = responseData.data || responseData;
        setEnqReports(exeData);
        setNumber(response.data.last_page);
        setFilterDateFrom(response.data.from_date);
        setFilterDateTo(response.data.to_date);
        
        setLoading(false);
      } else {
        console.error('Error fetching reports:', response.data.message);
      }

    } catch (error) {
      console.error('Error fetching reports:', error);
      setLoading(false);
    }
  }

  const getDeviceInfo = () => {
    return {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        appVersion: navigator.appVersion,
    };
  };
  const getIpAddress = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address', error);
        return null;
    }
  };
  const downloadCSV = async(duration)=>
  {
    const ipAddress = await getIpAddress();
    const deviceInfo = getDeviceInfo();
    try {
      const response = await dashboardApi.get('get-hourly-enquiry-csv-reports', {
        params: 
        {
          emp_id: emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          duration: selectedDuration,
          ipAddress:ipAddress,
          deviceInfo:deviceInfo,
        },
       responseType: 'blob',
    });
      const blob = new Blob([response.data], { type: 'text/csv'});
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `enq_info_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  }


  const viewHourlyEnquiryReports = async (duration,product_id) => 
  {
    setDuration(duration);
    try {
      const response = await dashboardApi.get(`get-view-hourly-enquiry-reports`, {
        params: {
          emp_id: emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          duration: duration,
          product_id: product_id,
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        setViewEnqReports(responseData);
      } else {
        console.error('Error fetching reports:', response.data.message);
      }

    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  }
  

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <ReportsDetailsHeader />
                  </div>
                </div>
                <div className='row'>

                  <div className="col-sm-2">
                    <label htmlFor="fromDate">From Date:</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-control"
                      onChange={handleFromDateChange}
                      value={selectFromDate}
                    />
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="toDate">To Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={selectToDate}
                      onChange={handleToDateChange}
                    />
                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                  </div>


                  <div className="col-sm-1">
                    <br />
                    <button className="btn btn-primary" onClick={getEenquiryReports}>Search</button>
                  </div>
                  

                </div>

                {loading ? (
                  <p style={{ align: 'center' }}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }} alt="Loading" />
                  </p>
                ) : (
                  <div style={{ overflowY: "hidden" }}>
                    <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>

                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Name</th>
                          <th>Houre</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enqReports && enqReports.map((enq, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{enq.product_name}</td>
                            <td>{enq.duration}</td>
                            <td>{enq.created_date}</td>
                            <td>
                              <span className="btn btn-primary" onClick={()=>viewHourlyEnquiryReports(enq.duration,enq.product_id)}>View</span>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="17">
                            <Pagination className="custom-pagination">
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={number}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link-style"}
                                breakLinkClassName={"break-link-style"}
                                previousLinkClassName={"prev-link-style"}
                                nextLinkClassName={"next-link-style"}
                                forcePage={currentPage}
                              />
                            </Pagination>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}

                {selectedDuration &&
                  <div style={{ overflowY: "hidden" }}>
                    <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>
                      <thead>
                        <tr>
                          <td colspan="9">
                       
                              <button className="btn btn-primary" onClick={downloadCSV}>
                                <span class="material-icons-outlined">
                                file_download
                                </span>
                              </button>
                 
                          </td>
                        </tr>
                        <tr>
                          <th>S.No</th>
                          <th>Product Name</th>
                          <th>Service Name</th>
                          <th>Category Name</th>
                          <th>From Address</th>
                          <th>To Address</th>
                          <th>Sent Status</th>
                          <th>Report Time</th>
                          <th>Enq Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewEnqReport && viewEnqReport.map((enq, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{enq.product_name}</td>
                            <td>{enq.service_name}</td>
                            <td>{enq.category_name}</td>
                            <td>{enq.from_address}</td>
                            <td>{enq.to_address}</td>
                            <td>{enq.status_name}</td>
                            <td>{enq.duration}</td>
                            <td>{enq.enq_date}</td> 
                          </tr>
                        ))}
                      </tbody>
                     
                    </table>
                  </div>
                }

              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );

}

export default Hourly_enquiry_report;
