import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';


import ReportsDetailsHeader from 'layouts/common_components/reports_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';

function Cs_reports()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csReports, setCsReports] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [selectFromDate,setFilterDateFrom] = useState('');
  const [selectToDate,setFilterDateTo] = useState('');
  const [dateError, setError] = useState('');

  useEffect(()=>{
    getExeDetails();
  },[]);

  useEffect(()=>{
    getExeWiseReports();
  },[currentPage])

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setError('');
      }
    }
  };

  const getExeWiseReports = async () => {
      try {
        const response = await dashboardApi.get(`get-cs-exe-wise-reports`,{
          params:{
            emp_id:emp_id,
            from_date:selectFromDate,
            to_date:selectToDate,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });

        if (response.data.status === 200)
        {
          const responseData = response.data.data;
          const exeData = responseData.data || responseData;
          setCsReports(exeData);
          setNumber(responseData.last_page);
          setFilterDateFrom(responseData.from_date);
          setFilterDateTo(responseData.to_date);
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

   

    const getExeDetails = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
        if (response.data.status === 200) {
          setExeDetails(response.data.data);
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }catch (error){
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

  return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-12">
                          <ReportsDetailsHeader></ReportsDetailsHeader>
                      </div>
                  </div>
                  <div className='row'>

                    <div className='col-sm-3'>
                        <label>Select Eexecutive</label>
                        <select className="form-control"
                          value={selectedEmp}
                          onChange={(e) => setSelectedEmp(e.target.value)}
                          >
                          <option value=''>Select Eexecutive</option>
                          {exeDetails.map((ed) => (
                          <option key={ed.emp_id} value={ed.emp_id}>
                          {ed.emp_fname} {ed.emp_lname}
                          </option>
                          ))}
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <label htmlFor="fromDate">From Date:</label>
                        <input
                          type="date"
                          id="fromDate"
                          className="form-control"
                          onChange={handleFromDateChange}
                          value={selectFromDate}
                        />
                    </div>

                    <div className="col-sm-3">
                      <label htmlFor="toDate">To Date:</label>
                      <input
                        type="date"
                        className="form-control"
                        value={selectToDate}
                        onChange={handleToDateChange}
                      />
                      {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                    </div>
                    <div className="col-sm-3">
                      <button className="btn btn-primary" onClick={getExeWiseReports}>Search</button>
                    </div>
                      
                  </div>


                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                ) : (
                  <div style={{overflowY: "hidden"}}>
                    <table className="table table-bordered table-hovered" style = {{marginTop:'20px'}}>
                    
                    <tr>
                      <td>S.No</td>
                      <td>Employee Name</td>
                      <td>
                        <tr>
                         
                          <td>Total Enquiry</td>
                          <td>Valid Enquiry</td>
                          <td>Sent Enquiry</td>
                          
                        </tr>
                      </td>
                    </tr>

                    {csReports && csReports.map((cr,index)=>(
                      <tr>
                          <td>{index + 1}</td>
                          <td>{cr.emp_name}</td>
                          <td>
                            <tr>
                                <td>{cr.attempt_enquiry}</td>
                                <td>{cr.valid_enquiry}</td>
                                <td>{cr.sent_enquiry}</td>
                            </tr>
                          </td>
                          
                      </tr>
                      ))
                    }
                    <tr>
                      <td colspan="6">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={number}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link-style"}
                          breakLinkClassName={"break-link-style"}
                          previousLinkClassName={"prev-link-style"}
                          nextLinkClassName={"next-link-style"}
                        />
                        </Pagination>
                      </td>
                    </tr>
                </table>
                </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
  
}
export default Cs_reports;