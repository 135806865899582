import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link,useParams } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import docurl from 'layouts/common_components/doc_base_url'; 
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


function Verfied_kyc() 
{
  const navigate = useNavigate();
  const { clientId } = useParams();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [newKycDoc,setNewKycDoc] = useState([]);
  const [packageAdminStatus,setPackageAdminStatus] = useState([]);
  const [approvalModal,setApprovalModal] = useState(false);
  const [docId,setDocId]=useState();
  const [docClientId,setClientId]=useState();
  const [docNumber,setDocNumber]=useState();
  const [docName,setDocName]=useState();

  const [selectedApprovalStatus,setApprovalStatus] = useState();
  const [remarks,setRemarks] = useState();
  const [adminModal,setAdminModal] = useState(false);
  const [packageId,setPackageId] = useState('');
  const [packageName,setPackageName] = useState('');
  const [packageCategory,setPackageCategory] = useState('');
  const [adminStatus,setAdminStatus] = useState('');
  const [adminRemarks,setAdminRemarks] = useState('');
  const [returnMessage,setReturnMessage] = useState('');
  
  const [productId,setPackageProductId] = useState('');
  const [companyId,setPackageCompId] = useState('');
  const [orgType,setCompanyOrgType] = useState('');
  
    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }

    const closeAdminControl = () =>
    {
      setAdminModal(false);
      setPackageId('');
      setPackageName('');
      setAdminStatus('');
      setAdminRemarks('');
      setPackageCategory('');
      setPackageProductId('');
      setPackageCompId('');
      setReturnMessage('');
      setCompanyOrgType('');
    }

    useEffect(() => {
      getNewKycDetails();
    }, []);

    const adminPackage = async (pkgId) =>
    { 
      try
      {
        const response = await dashboardApi.get(`update-admin-package-status`,{
          params: {
            emp_id: emp_id,
            pkgId: pkgId,
            adminRemarks:adminRemarks,
            adminStatus:adminStatus,
            packageCategory:packageCategory,
            company_id:companyId,
            product_id:productId,
            orgType:orgType,
          }
        });
       
        if(response.data.status === 200)
        {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          //setPackageAdminStatus(response.data);
          closeAdminControl();
        }
        else
        {
          setReturnMessage(response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }

    const getNewKycDetails = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-client-kyc-details/${clientId}`);
        if(response.data.status === 200)
        {
          setNewKycDoc(response.data.data);
          setPackageAdminStatus(response.data.packageDetails);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }

    const openAdminModal = (pkgId,packageName,category_id,product_id,comp_id,orgType) => {
      setPackageId(pkgId);
      setPackageName(packageName);
      setPackageCategory(category_id);
      setPackageProductId(product_id);
      setPackageCompId(comp_id);
      setCompanyOrgType(orgType);
      setAdminModal(true);
    }

    const approveClientDocument = async (doc_id)=>
    {
      const response = await dashboardApi.get('approve-client-document',{
        params:{
          doc_id:doc_id,
          status:selectedApprovalStatus,
          remarks:remarks,
          created_by:emp_id
        }
      });
      if (response.data.status === 200)
      {
        setApprovalModal(false);
        getNewKycDetails();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //setLmartPrePackageDetails(response.data.data);
      } 
    }

    const approvalOpenControl= async (id,client_id,doc_number,document_name)=>
    {
      setDocId(id);
      setClientId(client_id);
      setDocNumber(doc_number);
      setDocName(document_name);
      setApprovalModal(true);
    }

    return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <AdminDetailsHeader></AdminDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                      <Link className='btn btn-info'>
                          +
                      </Link>
                      </div>
                  </div>

                  

                  <>
                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                    <tr>
                      <td>S.No</td>
                      <td>Company & Document Details</td>
                      <td>Document</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                    {newKycDoc && newKycDoc.map((kyc,index)=>(
                     
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          Client Name: <b>{kyc.client_name}</b> <br/>
                          Company Name:<b>{kyc.business_name}</b> <br/>
                          Mobile No: <b>{kyc.mobile_no} </b><br/>
                          Email ID: <b>{kyc.emaal}</b> <br/>
                          Address : <b>{kyc.address}</b><br/>
                          {kyc.document_name}: <b>{kyc.doc_number}</b> <br/>
                          Executive : <b>{kyc.emp_name}</b>
                        </td>
                        <td>
                          <div className="row">
                            {kyc.doc_url && kyc.doc_url.split(',').map((item) => 
                              <div className="col-sm-4">
                                <iframe style={{margin:'20px',padding:'10px;'}} src={docurl()+item} title="document" width="250px" height="250px"></iframe>
                                <a href={docurl()+item} target='_blank' className="btn btn-primary">View</a>
                                
                              </div>
                            )}
                          </div>
                          
                          </td>
                        <td>
                          {kyc.status==0 &&<span style={{color:'red'}}>Pending</span>}
                          {kyc.status==1 &&<span style={{color:'green'}}>Approved</span>}
                          {kyc.status==2 &&<span style={{color:'red'}}>Rejected</span>}
                        </td>
                        <td><button className="btn btn-primary" onClick={() => approvalOpenControl(kyc.id,kyc.client_id,kyc.doc_number,kyc.document_name) }>Approve</button></td>
                      </tr>
                      ))
                    }
                  </table>

                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr>
                        <td>S.No</td>
                        <td>Company Name</td>
                        <td>Package Name</td>
                        <td>Duration</td>
                        <td>Package Type</td>
                        <td>Status</td>
                        <td>Date</td>
                        <td>Created By</td>
                        <td>Executive Name</td>
                        <td>Action</td>
                      </tr>
                    {packageAdminStatus && packageAdminStatus.map((pas,index)=>
                      <tr>
                        <td>{index +1}</td>
                        <td>{pas.business_name}</td>
                        <td>{pas.package_name}</td>
                        <td>{pas.duration}</td>
                        <td>{pas.package_type}</td>
                        <td>{pas.admin_status==0?'Pending':'Approved'}</td>
                        <td>{pas.created_date}</td>
                        <td>{pas.created_by}</td>
                        <td>{pas.emp_name}</td>
                        <td><span className="btn btn-primary btn-sm" onClick={()=> openAdminModal(pas.package_id,pas.package_name,pas.category_id,pas.product_id,pas.comp_id,pas.org_type)}>View Details</span></td>
                      </tr>
                    )}
                  </table>
                  </>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={approvalModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Document Details</DialogTitle>
          <DialogContent style={{height:"350px"}}>
              <form>
                <div className="row">
                  
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Document Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-12">
                    <table className="table table-bordered table-hovered">
                      <tr>
                        <td>
                          <b>Document Name : {docName}</b>  <br/>
                          <b>Document No : {docNumber}</b> 
                        </td>
                      </tr>
                      <></>
                    </table>
                  </div>
            
                  <div className="col-sm-6">
                      <label>Select Status</label>
                      <select className="form-control"
                          value={selectedApprovalStatus}
                          onChange={(e) => setApprovalStatus(e.target.value)}
                          >
                          <option value=''>Select Approval Status</option>
                          <option value='1'>Approved</option>
                          <option value='2'>Rejected</option>
                          
                      </select>
                  </div>

                  <div className="col-sm-6">
                      <label>Remarks</label>
                      <input type="text" className="form-control" value={remarks} onChange={(e)=>setRemarks(e.target.value)} />
                  </div>
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closeApprovalControl}>Close</Link>
              <Link className="btn btn-success btn-sm"  onClick={() => approveClientDocument(docId)}>Submit</Link>
           
          </DialogActions>
      </Dialog>

      <Dialog open={adminModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Package Details</DialogTitle>
          <DialogContent style={{height:"350px"}}>
              <form>
                <div className="row">
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Package Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-12">
                    <table className="table table-bordered table-hovered">
                      <tr>
                        <td>
                          <b>Package Id : {packageId}</b>  <br/>
                          <b>Package Name : {packageName}</b>  <br/>
                        </td>
                      </tr>
                      <></>
                    </table>
                  </div>
            
                  <div className="col-sm-6">
                      <label>Select Status</label>
                      <select className="form-control"
                          value={adminStatus}
                          onChange={(e) => setAdminStatus(e.target.value)}
                          >
                          <option value=''>Select Status</option>
                          <option value='1'>Activate</option>
                          <option value='2'>InActivate</option>
                      </select>
                  </div>

                  <div className="col-sm-6">
                      <label>Remarks</label>
                      <input type="text" className="form-control" value={adminRemarks} onChange={(e)=>setAdminRemarks(e.target.value)} />
                  </div>

                  <p style={{color:'red'}}>{returnMessage}</p>
                </div>
            </form>
          </DialogContent>

          <DialogActions>
            <Link className="btn btn-primary btn-sm" onClick={closeAdminControl}>Close</Link>
            <Link className="btn btn-success btn-sm"  onClick={() =>adminPackage(packageId)}>Submit</Link>
          </DialogActions>
      </Dialog>
    

    </DashboardLayout>
  );
}
export default Verfied_kyc;
