import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import secureLocalStorage from "react-secure-storage";
import Cs_reports from "layouts/reports/cs-reports";
import All_client_reports from "layouts/reports/all-clients-reports";
import Enquiry_report from "layouts/reports/enquiry-report";
import Hourly_enquiry_report from "layouts/reports/hourly-enquiry-report";
import Download_reports from "layouts/reports/download-reports";

const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
const dept_id = secureLocalStorage.getItem('department_id');

const nested = []; // Define nested array before using it

const reportsroute = [
  {
    type: "collapse",
    name: "Reports",
    key: "All Type Reports",
    icon: <Icon fontSize="small">account_tree</Icon>,
    nested: nested,
  }
];

if (dept_id == 9 || dept_id == 10 || dept_id == 4) {
  nested.push({
    route: "/enquiry-report",
    name: "Enquiry Reports",
    key: "Enquiry Reports",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    component: (token != null) ? <Enquiry_report name='enquiry-report' /> : <SignIn />,
  });
}

if (dept_id == 9 || dept_id == 4) {
  nested.push({
    route: "/cs-reports",
    name: "CS Reports",
    key: "CS Reports",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    component: (token != null) ? <Cs_reports name='cs-reports' /> : <SignIn />,
  });
}

if (dept_id == 9) {
  nested.push({
    route: "/all-clients-reports",
    name: "All Client Reports",
    key: "All Client Reports",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    component: (token != null) ? <All_client_reports name='all-clients-reports' /> : <SignIn />,
  });
}

if (dept_id == 9) {
  nested.push({
    route: "/hourly-enquiry-report",
    name: "Hourly Enquiry Reports",
    key: "Hourly Enquiry Reports",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    component: (token != null) ? <Hourly_enquiry_report name='hourly-enquiry-report' /> : <SignIn />,
  });
}

if (dept_id == 9) {
  nested.push({
    route: "/download-report",
    name: "Download Reports", 
    key: "Download Reports", 
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    component: (token != null) ? <Download_reports name='download-report' /> : <SignIn />,
  });
}

if (dept_id == 9 || dept_id == 10 || dept_id == 4) {
  reportsroute.push({
    route: "/enquiry-report",
    component: (token != null) ? <Enquiry_report name='enquiry-report' /> : <SignIn />,
  });
}

if (dept_id == 9 || dept_id == 4) {
  reportsroute.push({
    route: "/cs-reports",
    component: (token != null) ? <Cs_reports name='cs-reports' /> : <SignIn />,
  });
}

if (dept_id == 9) {
  reportsroute.push({
    route: "/all-clients-reports",
    component: (token!=null) ? <All_client_reports name ='all-clients-reports'/> :  <SignIn/>,
  });
}

if (dept_id == 9) {
  reportsroute.push({
   route: "/get-all-client-reports-downlode",
    component: (token!=null) ? <All_client_reports name ='get-all-client-reports-downlode'/> :  <SignIn/>,
  });
}

if (dept_id == 9) {
  reportsroute.push({
   route: "/hourly-enquiry-report",
    component: (token!=null) ? <Hourly_enquiry_report name ='hourly-enquiry-report'/> :  <SignIn/>,
  });
}

if (dept_id == 9) {
  reportsroute.push({
  route: "/download-report",
    component: (token!=null) ? <Download_reports name ='download-report'/> :  <SignIn/>,
  });
}

export default reportsroute;
