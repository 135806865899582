import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';

function Lmart_tollfree() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [tollFreeEnq,setTollFreeEnq] = useState([]);
  const [loading, setLoading] = useState(true);
 
  const [selectedStatus,setStatus] = useState();
  const [remarks,setRemarks] = useState();
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);

  const [selectFromDate,setFilterDateFrom] = useState('');
  const [selectToDate,setFilterDateTo] = useState('');
  const [dateError, setError] = useState('');

  const [addCommentModal,setCommentModal] = useState(false);
  const [callId,setCallId] = useState();
  
  const [validationErrors, setValidationErrors] = useState({});

  const [historyModal,setHistoryModal] = useState(false);
  
  const [customerHistory,setCustomerHistory] = useState();
  const [callConnecting, setCallLoading] = useState(false);
  const [callingMobileNo,setCallingMobileNumber] = useState('');
    const onCommentModal = (id)=>
    {
      setCallId(id);
      setCommentModal(true);
    }

    const ofCommentModal = ()=>
    {
      setCommentModal(false);
    }

    const handleFromDateChange = (e) => {
      setFilterDateFrom(e.target.value);
      validateDates(e.target.value, selectToDate);
    };
  
    const handleToDateChange = (e) => {
      setFilterDateTo(e.target.value);
      validateDates(selectFromDate, e.target.value);
    };
  
    const validateDates = (from, to) => {
      if (from && to) {
        const fromDateObj = parseISO(from);
        const toDateObj = parseISO(to);
  
        if (isBefore(toDateObj, fromDateObj)) {
          setError('To date cannot be before from date.');
          setFilterDateTo('');
        } else {
          setError('');
        }
      }
    };
  
  
      const ofHistoryModal = ()=>
      {
        setHistoryModal(false);
      }

  
    useEffect(() => {
      getLmartTollFree();
    }, [currentPage]);

    const hideMiddleNumber = (phoneNumber) => {
      if (phoneNumber.length < 10) {
        return phoneNumber; // Return the original number if it's too short
      }
      return phoneNumber.slice(0, 3) + '****' + phoneNumber.slice(-3);
    };

    
      const getCustomerHistory = async (mobileNo) => 
      {
          setHistoryModal(true);
          try{
            const response = await dashboardApi.get(`get-customer-history`,{
              params:{
                mobileNo:mobileNo
              }
            });
            if(response.data.status === 200)
            {
              setCustomerHistory(response.data.data);
            }
            else
            {
              console.error('Error fetching countries:', response.data.message);
              setLoading(false);
            }
          }
          catch (error)
          {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        
    

    const updateComment = async ()=>
    {
      const errors = {};

      if(!selectedStatus)
      {
        errors.selectedStatus = 'Status is required.';
      }

      if(!remarks)
      {
        errors.remarks = 'Remarks is required.';
      }
    
      setValidationErrors(errors);
      if(Object.keys(errors).length > 0)
      {
        return; 
      }
      
      const formData = new FormData();
     
      formData.append('status',selectedStatus);
      formData.append('remarks',remarks);
      formData.append('created_by',emp_id);
      formData.append('callId',callId);
       
      try 
      {
          const response = await dashboardApi.post('update-lead-info-details', formData);
            if(response.data.status === 200)
            {
              setCommentModal(false);
              Swal.fire({
                icon: 'success',
                text: response.data.message,
              });
              getLmartTollFree();
            }
            else
            {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
    }

    const getLmartTollFree = async () => 
    {
      try{
        const response = await dashboardApi.get(`lmart-toll-free`,{
          params:{
            emp_id:emp_id,
            date_from:selectFromDate,
            date_to:selectToDate,
            per_page: postPerPage,
            page: currentPage + 1,
          }
          
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setFilterDateFrom(response.data.from_date);
          setFilterDateTo(response.data.to_date);
          setNumber(responseData.last_page);
          setTollFreeEnq(customerData);
          setLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          setLoading(false);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const makeCallToCustomer = async (mobile_no,enqId) => 
    {
          setCallingMobileNumber(mobile_no);
          const token = secureLocalStorage.getItem('token'); 
          const form = new FormData();
          form.append("emp_id",emp_id);
          form.append("mobile_number",mobile_no);
          form.append("api_id", "1");
          form.append("enqId", enqId);
          form.append("call_type", 1);
          form.append("secure_key", "d53e5ccd3d0ab16e6c29103beb806bc1");
          try {
            setCallLoading(true);
            
            const response = await dashboardApi.post('dialer/make-a-call', form);
            if(response.data.status === 200)
            {
              const responseData = response.data.data;
              const customerData = responseData.data || responseData;
              setCallLoading(false);
            }
            else
            {
              console.error('Error fetching countries:', response.data.message);
              setCallLoading(false);
            }
          } catch (error) {
            console.error('Error sending POST request', error);
            setCallLoading(false);
          }
      };


    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>

                  <div className="row my-3">
                    
                      <div className="col-sm-3">
                        <label htmlFor="fromDate">From Date:</label>
                        <input
                          type="date"
                          id="fromDate"
                          className="form-control"
                          onChange={handleFromDateChange}
                          value={selectFromDate}
                          //onChange={(e) => setFilterDateFrom(e.target.value)}
                        />
                      </div>

                      <div className="col-sm-3">
                        <label htmlFor="toDate">To Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={selectToDate}
                          onChange={handleToDateChange}
                          //onChange={(e) => setFilterDateTo(e.target.value)}
                        />
                        {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                      </div>
                      
                      <div className="col-sm-3 d-flex align-items-end">
                        <button type="submit" className="btn btn-primary" onClick={()=>{getLmartTollFree()}}>Search</button>
                      </div>
                   
                  </div>
                  

                 
                  {loading ? (
                    <p style={{align:'center'}}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                    </p>
                  ) : (
                    <div style={{overflowY: "hidden"}}>
             
                        <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                          <tr>
                              <th>S.No</th>
                              <th>Contact No</th>
                              <th>Call Status</th>
                              <th>Call comment</th>
                              <th>Recording</th>
                              <th>Call Time</th>
                              <th>Ring Time</th>
                              <th>Call Number</th>
                              <th>Duration</th>
                              <th>Action</th>
                          </tr>

                          {tollFreeEnq && tollFreeEnq.map((enq, index) => (
                            <tr key={enq.id}>
                              <td>{index + 1}</td>
                              <td>
                                {hideMiddleNumber(enq.caller_id_number)}
                              </td>
                              <td>{enq.call_status}</td>
                              <td>
                                <span>{enq.call_comment}</span>
                              </td>
                            
                              <td>
                                {enq.recording_url ? (
                                  <audio controls>
                                    <source src={enq.recording_url} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                  </audio>
                                ) : null}
                              </td>
                              <td>{enq.start_stamp}</td>
                              <td>{enq.agent_ring_time}</td>
                              <td>{enq.call_to_number}</td>
                              <td>{enq.duration}</td>
                              <td className='d-flex'>

                              {callConnecting && callingMobileNo == enq.primary_no ?(
                                <p style={{align:'center'}}>
                                <img className="btn btn-primary btn-sm mx-1" src={`${process.env.PUBLIC_URL}/callConnecting.gif`} style=   {{height:'40px'}}></img>
                                  </p>
                              ):(
                                <>
                              <span class="material-icons-outlined btn btn-primary btn-sm mx-1" onClick={()=>   {makeCallToCustomer(enq.caller_id_number,enq.id)}}>
                                call
                              </span>
                              </>
                              )}

                                {/* <span className="material-icons-outlined btn btn-primary btn-sm mx-1" onClick={()=>{makeCallToCustomer(enq.primary_no)}}>
                                  call
                                </span> */}
                                
                                <span onClick={()=>{getCustomerHistory(enq.caller_id_number)}} class="material-icons-outlined btn btn-danger btn-sm mx-1">
                                  history
                                </span>
                               
                                <span class="material-icons-outlined btn btn-info btn-sm mx-1" onClick={()=>{onCommentModal(enq.id)}}>
                                  add_comment
                                </span>

                              
                                <Link target="_blank" to={`/add-tollfree-enquiry/${enq.caller_id_number}`}>
                                  <span class="material-icons-outlined btn btn-success btn-sm mx-1">
                                    add
                                  </span>
                                </Link>
                              
                                
                                
                              </td>
                            </tr>
                          ))}
                          
                          <tr>
                          <td colspan="11">
                            <Pagination className="custom-pagination">
                              <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={number}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link-style"}
                              breakLinkClassName={"break-link-style"}
                              previousLinkClassName={"prev-link-style"}
                              nextLinkClassName={"next-link-style"}
                            />
                            </Pagination>
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={addCommentModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Comment</DialogTitle>
          <DialogContent style={{height:"auto"}}>
              <form>
                <div className="row">
                  <div className="col-sm-12">
                    <label>Select Status</label>  
                    <select className="form-control" value={selectedStatus} onChange={(e)=>{setStatus(e.target.value)}}>
                      <option value="1">Lead Verified</option>
                      <option value="2">Escalation</option>
                      <option value="3">Other</option>
                      <option value="4">Followup</option>
                      <option value="5">Business Lead</option>
                    </select>
                    {validationErrors.selectedStatus && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedStatus}</div>
                    )}
                  </div>

                  <div className="col-sm-12">
                      <label>Remarks</label>  
                      <input type="text" className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
                      {validationErrors.remarks && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remarks}</div>
                      )}
                  </div>
                </div>
              </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={ofCommentModal}>Close</Link>
              <Link className="btn btn-success btn-sm" onClick={updateComment}>Add Details</Link>
          </DialogActions>
      </Dialog>

      <Dialog open={historyModal} fullWidth PaperProps={{ style: { maxWidth: '100%', padding: '20px' } }}>
        <DialogTitle>Customer History</DialogTitle>
          <DialogContent style={{height:"auto"}}>
              <form>
                <div className="row">
                  <div style={{overflowY: "hidden"}}>
             
                    <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr>
                        <th>Id</th>
                        <th>Enq id</th>
                        <th>Customer Name</th>
                        <th>Address From</th>
                        <th>Address To</th>
                        <th>Sent Count</th>
                        <th>Product</th>
                        <th>Service</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Event Date</th>
                        <th>Otp Verified</th>
                        <th>CS Verified</th>
                        <th>Verified By</th>
                        <th>Verified Date</th>
                        <th>Recived Date</th>
                      </tr>
                      {customerHistory && customerHistory.map((custHis,index)=>(
                      <tr>
                        <td>{index+1}</td>
                        <td>{custHis.id}</td>
                        <td>{custHis.name}</td>
                        <td>{custHis.address_from}</td>
                        <td>{custHis.address_to}</td>
                        <td>{custHis.sent_count}</td>
                        <td>{custHis.product_name}</td>
                        <td>{custHis.service_name}</td>
                        <td>{custHis.category_name}</td>
                        <td>{custHis.enq_status==1?'Sent':custHis.enq_status==2?'Dublicate':custHis.enq_status==3?'Not Sent':'Pending'}</td>
                        <td>{custHis.event_date}</td>
                        <td>{custHis.otp_verified==1?'Yes':'no'}</td>
                        <td>{custHis.cs_verified==1?'Yes':'no'}</td>
                        <td>{custHis.verified_by}</td>
                        <td>{custHis.verifed_date}</td>
                        
                        <td>{custHis.recived_date}</td>
                        
                      </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={ofHistoryModal}>Close</Link>
          </DialogActions>
      </Dialog>

      
    </DashboardLayout>
  );
}
export default Lmart_tollfree;
