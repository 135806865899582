import React from 'react';
import { Link, useLocation } from "react-router-dom";
function ReportsDetailsHeader() 
{
  let location = useLocation();
  let currentPage = location.pathname;
  return (
    <div>
      <Link style={currentPage === "/cs-reports" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/cs-reports">
        Executive Reports
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/all-clients-reports" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/all-clients-reports">
        Client Reports
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/enquiry-report" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/enquiry-report">
        Enquiry Reports
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/hourly-enquiry-report" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/hourly-enquiry-report">
        Hourly Enquiry Reports
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/download-report" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/download-report">
        Download Reports
      </Link>
    </div>
  );
}
export default ReportsDetailsHeader;
