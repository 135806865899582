import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

const SalesFunnelDetils = () => {
  const navigate = useNavigate();
  const { sales, tasks } = reportsLineChartData;
  const empId = secureLocalStorage.getItem('emp_id');
  const [loading, setLoading] = useState(true);
  const [product, setproduct] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam,getmanagersteam] = useState([]);
  const [selectedemp,setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status,get_followup_details] = useState([]);
  const {details} = useParams();
  const [inner_page_details,get_inner_page_details]  = useState([]);
  const [sum,get_sum] = useState();
  const [manager_team,get_manager_team] = useState([]);
  const[checkmanager,setcheckmanager] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  //const [followup_status,get_followup_details] = useState([]);

  useEffect(() => {
    productdetails();
   // fetchdata();
    get_emp_list();
     get_sales_manager_team();
     groupdetails();
  }, []);

   useEffect(() => {
    fetchlist();
  }, [product, end_date, start_date,category,group,service,selectedmanagers,selectedemp]);

   useEffect(()=>{
    fetch_followup_data();

  },[product, end_date, start_date, service,selectedmanagers,selectedemp]);



   const downloadata = async () => {
          //emp_id: empId,

    try {
      const response = await dashboardApi.get('sales-inner-page-details', {
       params: {
         download: 'csv',
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details,
          group:group,
        },
       responseType: 'blob',
    });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `sales_data_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };

  const get_sales_manager_team = async()=>{
     try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const groupdetails = async () => {
    try {
      const endpoint = `get-group-based-on-sales-emp/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
   const fetch_followup_data = async () => {
    try {
      const response = await dashboardApi.get('get-followup-status-details', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category:category,
          service:service,
          manager:selectedmanagers,
          employee:selectedemp,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        get_followup_details(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const followup_details = async () => {
    try {
      const response = await dashboardApi.get('get-followup-status-details', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category:category,
          service:service,
          manager:selectedmanagers,
          employee:selectedemp,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        get_followup_details(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

   useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);





  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);


  const handlePageClick = (selected) => {
    console.log(selected);
    setCurrentPage(selected.selected);
  };

  const fetchdata = async () => {
    try {
      const response = await dashboardApi.get('count-sales-data', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          manager:selectedmanagers,
          employee:selectedemp,
          group:group,
          category:category,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        getsales_data(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchlist = async () => {
    try {
      const response = await dashboardApi.get('count-sales-data', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          manager:selectedmanagers,
          employee:selectedemp,
          group:group,
          category:category,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        getsales_data(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
          <Link to='/sales-dashboard' className='btn btn-info btn-sm'>Back</Link>
            <div className="d-flex flex-wrap">
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={group}
                  onChange={(e) => setgroup(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Group</option>
                  {grouplist.map((row) => (
                    <option key={row.group_id} value={row.group_id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Product</option>
                  {productlist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={service}
                  onChange={(e) => setservice(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Service</option>
                  {servicelist.map((ser) => (
                    <option key={ser.id} value={ser.id}>
                      {ser.service_name}
                    </option>
                  ))}
                </select>
              </div>

               <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>

              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={start_date}
                  onChange={(e) => setstart_date(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={end_date}
                  onChange={(e) => setend_date(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              {empId === 'RIMS1' && (
               <>
             <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedmanagers}
                  onChange={(e) => setselectedmanagers(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Managers</option>
                   {emp_list.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>

                  <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedemp}
                  onChange={(e) => setselectedemp(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                   {managersteam.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>
                 </>
                 )}

               {checkmanager === 1 && (
                <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedemp}
                  onChange={(e) => setselectedemp(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                   {manager_team.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>

               )}


            </div>
          </div>


          <div className="card-body">
           <div className="d-flex flex-wrap">
            <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/followup-details/${encodeURIComponent('meeting_followup')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/discussion.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Followup For Meeting</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.meeting}</h5>
                </div>
              </div>
              </Link>

            </div>
            <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/followup-details/${encodeURIComponent('payment_followup')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/payment-method.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Followup For Payment</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.followup_for_payment}</h5>
                </div>
              </div>
              </Link>

            </div>

             <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/followup-details/${encodeURIComponent('business_proposal')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/business-proposal.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Business Proposal</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.proposal}</h5>
                </div>
              </div>
              </Link>

            </div>
             <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/followup-details/${encodeURIComponent('no_of_call_connected')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/contact-mail.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>No Of Call Connected</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.connected_call}</h5>
                </div>
              </div>
               </Link>

            </div>
            </div>

              <div className="d-flex flex-wrap">
            <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/followup-details/${encodeURIComponent('mature_followup')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/man.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Mature Followup</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.mature}</h5>
                </div>
              </div>
              </Link>

            </div>
            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            <Link className='h-100'
                to={`/followup-details/${encodeURIComponent('dead_followup')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/dead.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Dead Followup</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.dead}</h5>
                </div>
              </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/carry-forward/${encodeURIComponent('carry-forward')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/next.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Clients With No Of Times Carry Forward Followups</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.carry_forward}</h5>
                </div>
              </div>
              </Link>

            </div>

             <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/accuracy.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Forcast Accuracy Percent</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.accuracy}%</h5>
                </div>
              </div>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            <Link className='h-100'
                to='/due-amount'
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/due.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Due Amount</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.due_amount}</h5>
                </div>
              </div>
              </Link>

            </div>

             <div className='col-sm-3 col-6 p-sm-2 p-1'>
             <Link className='h-100'
                to={`/upcoming-renewal-details/${encodeURIComponent('upcomingRenewal')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/renewal.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Upcoming Renewals/Amount</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sales_data.renewal_data}/{sales_data.renewal_amount}</h5>
                </div>
              </div>
              </Link>

            </div>
            </div>



          
          </div>
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default SalesFunnelDetils;
