import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";

function Customer_support() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [loading, setLoading] = useState(true);
  const [callConnecting, setCallLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [notSentEnq,setNotSentEnq] = useState([]);
  const [approvalModal,setApprovalModal] = useState(false);
  const [selectedApprovalStatus,setApprovalStatus] = useState();
  const [remarks,setRemarks] = useState();
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [callingMobileNo,setCallingMobileNumber] = useState('');

  const [enquiryModal ,setEnquiryModal]= useState(false);
  const [attemptsModal ,setAttemptsModal]= useState(false);
  const [enquiryDetails ,setEnquiryDetails]= useState([]);
  const [enquiryId ,setEnquiryId]= useState('');
  const [mobileNumber ,setMobileNumber]= useState('');
  const [attemptsDestils ,setAttemptsDetails]= useState([]);
  
  

    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
    
    useEffect(() => {
      getNotSentEnq();
    }, [currentPage]);

    const getNotSentEnq = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-not-sent-enquiry`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
            enqId:'',
            mobileNumber:''
          }
          
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setNotSentEnq(customerData);
          setLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }


    const getNotSentEnqById = async () => 
    {
      const errors = {};

      if (!enquiryId && !mobileNumber) {
        errors.enquiryId = 'Enquiry Id or mobile number is required.';
      } else if (enquiryId && mobileNumber) {
        
        errors.enquiryId = 'You can search by only one parameter, either Enquiry Id or Mobile Number.';
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
    
      try{
        const response = await dashboardApi.get(`get-not-sent-enquiry`,{
          params:{
            emp_id:emp_id,
            enqId:enquiryId,
            mobileNumber:mobileNumber,
            per_page: postPerPage,
            page: currentPage + 1,
          }
          
        });
        if(response.data.status === 200)
        {
          
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setNotSentEnq(customerData);
          setLoading(false);
         
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }

    const viewEnquiryDetails =(enq_id)=>
    {
      setEnquiryModal(true);
      getEnquireyDetails(enq_id);
    }

    const closeEnquiryModal =()=>{
      setEnquiryModal(false);
    }
    
    const closeAttemptsModal = ()=>{
      setAttemptsModal(false);
      setAttemptsDetails('');
    }


    const getEnquireyDetails = async(enq_id)=>
    {
      try {

        const response = await dashboardApi.get('get-enquiry-details-by-enqid',{
          params:{
            enqId:enq_id,
            emp_id:emp_id
          }
        });
        if(response.data.status === 200)
        {
          setEnquiryDetails(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
         
        }
      } catch (error) {
        console.error('Error sending POST request', error);
        
      }
    }
    
    const makeCallToCustomer = async (mobile_no,enqId) => 
    {
      setCallingMobileNumber(mobile_no);
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      const form = new FormData();
      form.append("emp_id",emp_id);
      form.append("mobile_number",mobile_no);
      form.append("api_id", "1");
      form.append("enqId", enqId);
      form.append("call_type", 1);
      form.append("secure_key", "d53e5ccd3d0ab16e6c29103beb806bc1");
      try {
        setCallLoading(true);
        
        const response = await dashboardApi.post('dialer/make-a-call', form);
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setCallLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          setCallLoading(false);
        }
      } catch (error) {
        console.error('Error sending POST request', error);
        setCallLoading(false);
      }
    };

      
    const viewAttemptDetails =(enqId)=>
    {
      setAttemptsModal(true);
      getAttemptedDetails(enqId);
    }
  
    const getAttemptedDetails = async(enqId) =>
    {
      try {
        const response = await dashboardApi.get('get-attempts-details-by-enqid',{
          params:{
            enqId:enqId,
            emp_id:emp_id
          }
        });
        if(response.data.status === 200)
        {
          setAttemptsDetails(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
         
        }
      } catch (error) {
        console.error('Error sending POST request', error);
        
      }
    }
    

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };
    //console.log(callingMobileNo);

    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>

                  <div className="row" style={{padding: '9px'}}>
                    <div className="col-sm-3">
                      <label>Enquiry Id</label>
                      <input type="text" className="form-control" value={enquiryId} onChange={(e)=>setEnquiryId(e.target.value)}/>
                      {validationErrors.enquiryId && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.enquiryId}</div>
                      )}
                    </div>

                    <div className="col-sm-3">
                      <label>Mobile Number</label>
                      <input type="text" className="form-control" value={mobileNumber} onChange={(e)=>setMobileNumber(e.target.value)}/>

                      {validationErrors.mobileNumber && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.mobileNumber}</div>
                      )}
                    </div>

                    <div className="col-sm-4">
                      
                      <button className="btn btn-primary btn-sm" style={{marginTop: '38px'}} onClick={getNotSentEnqById}>Search Enquiry Details</button>
                    </div>
                  </div>

                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
             
                    <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr>
                        <td>S.No</td>
                        <td>Enq Id</td>
                        <td>Customer Name</td>
                        <td>Contact No</td>
                        <td>From/To city</td>
                        <td>Service/Category</td>
                        <td>Recived Date</td>
                        <td>Event Date</td>
                        <td>No Of Attempt</td>
                        <td>Otp Verified</td>
                        <td>CS Verified</td>
                        <td>Action</td>
                      </tr>
                      {notSentEnq && notSentEnq.map((enq,index)=>(
                        <tr>
                          <td>{index + 1}</td>
                          <td>{enq.id}</td>
                          <td>{enq.name}</td>
                          <td>{enq.primary_no}</td>
                          <td>{enq.city_from} - {enq.city_to}</td>
                          <td>{enq.service_name} ({enq.category_name})</td>
                          <td>{enq.recived_date}</td>
                          <td>{enq.event_date}</td>
                          <td>{enq.no_of_attempt}</td>
                          <td>{enq.otp_verified}</td>
                          <td>{enq.cs_verified}</td>
                          <td className='d-flex'>
                              
                              {callConnecting && callingMobileNo == enq.primary_no ?(
                                <p style={{align:'center'}}>
                                <img className="btn btn-primary btn-sm mx-1" src={`${process.env.PUBLIC_URL}/callConnecting.gif`} style=   {{height:'40px'}}></img>
                                  </p>
                              ):(
                                <>
                              <span class="material-icons-outlined btn btn-primary btn-sm mx-1" onClick={()=>   {makeCallToCustomer(enq.primary_no,enq.id)}}>
                                call
                              </span>
                              </>
                              )}
                             
                             {(!enquiryId && !mobileNumber) ? (
                                <Link target="_blank" to={`/edit-enquiry/${enq.id}`}>
                                  <span className="material-icons-outlined btn btn-info btn-sm mx-1">
                                    edit_note
                                  </span>
                                </Link>
                              ) : (
                                <span
                                  className="material-icons-outlined btn btn-info btn-sm mx-1"
                                  onClick={() => viewEnquiryDetails(enq.id)}
                                >
                                preview
                                </span>
                              )}
                          </td>
                        </tr>
                        
                      ))
                      }
                      <tr>
                      <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={number}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link-style"}
                          breakLinkClassName={"break-link-style"}
                          previousLinkClassName={"prev-link-style"}
                          nextLinkClassName={"next-link-style"}
                        />
                        </Pagination>
                      </td>
                    </tr>
                    </table>
                  </div>
                  )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={enquiryModal} fullWidth PaperProps={{ style: { maxWidth: '70%', padding: '20px' } }}>
        <DialogTitle>Enquiry Details</DialogTitle>
          <DialogContent style={{height:"500px"}}>
              <form>
                <div className="row">
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Enquiry Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                  {enquiryDetails && enquiryDetails.map((enq)=>(
                  <>
                    <div className="col-sm-6">
                        Enq Id: <b>{enq.id}</b><br/>              
                        Customer Name:<b> {enq.name}</b><br/>               
                        Mobile Number:<b> {enq.primary_no} </b><br/>              
                        Service Name: <b>{enq.service_name}</b> <br/>              
                        Category Name: <b>{enq.category_name} </b><br/> 
                        Enquiry Status: <b>{enq.enq_status==0?<span style={{color:'red'}}>Pending</span>:enq.enq_status==1?<span style={{color:'green'}}>Sent</span>:enq.enq_status==2?<span style={{color:'red'}}> Duplicate</span>:enq.enq_status==3?<span style={{color:'red'}}>Not Sent</span>:''}</b><br/>   
                        Sent To: <b><span style={{color:'red'}}>{enq.sent_count} Clients </span></b>

                        {enq.clientDetails && enq.clientDetails.map((cd,index)=>(
                          <p>{index + 1}: <b><span style={{color:'red'}}> {cd.business_name}</span></b></p>
                        ))}              
                                      
                    </div>

                    <div className="col-sm-6">
                      City From: <b>{enq.city_from}</b> <br/>              
                      Address From:<b>{enq.address_from}</b><br/>               
                      City To: <b>{enq.city_to} </b><br/>              
                      Address To: <b>{enq.address_to}</b><br/>               
                      Received Date: <b>{enq.received_date}</b><br/>               
                      Event Date: <b>{enq.event_date}</b><br/>
                      Verifed Status : <b>OTP: <span style={{color:'red'}}>{enq.otp_verified==1?'Yes':'No'}</span> </b> <b>CS: <span style={{color:'red'}}>{enq.cs_verified==1?'Yes':'No'}</span></b><br/>
                      Verified Date: <b>{enq.verified_date}</b><br/>            
                      Source Url: <b>{enq.source}</b><br/>          
                      Source Type: <b>{enq.source_type}</b><br/>          
                      Group Name: <b>{enq.group_name}</b><br/>
                      No Of Attempt: <b>{enq.no_of_attempt}</b><br/>
                      Attempted By: <b>{enq.verified_by}</b><span className="btn btn-primary" onClick={()=>viewAttemptDetails(enq.id)}>View Details </span><br/>
                      Reason: <b>{enq.followup_title}</b><br/>
                      Remarks: <b>{enq.remarks}</b><br/>
                               
                    </div>
                  </>
                  ))}
                 

                  
                </div>
            </form>
          </DialogContent>

          <DialogActions>
            <Link className="btn btn-primary btn-sm" onClick={closeEnquiryModal}>Close</Link>
        
          </DialogActions>
      </Dialog>


      <Dialog open={attemptsModal} fullWidth PaperProps={{ style: { maxWidth: '30%', padding: '20px' } }}>
        <DialogTitle>Attempts Details</DialogTitle>
          <DialogContent style={{height:"350px"}}>
            
                <div className="row">
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Attempt Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                 
                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr>
                        <td>S.No</td>
                        <td>Enq Id</td>
                        <td>Followup Status</td>
                        <td>Executive Name</td>
                        <td>Date/time</td>
                      </tr>
                      {attemptsDestils && attemptsDestils.map((ad, index)=>(
                        <tr>
                          <td>{index + 1}</td>
                          <td>{ad.enq_id}</td>
                          <td>{ad.followup}</td>
                          <td>{ad.emp_name}</td>
                          <td>{ad.attempted_date}</td>
                        </tr>
                      ))}
                    </table>
                 

                  
                </div>
           
          </DialogContent>

          <DialogActions>
            <Link className="btn btn-primary btn-sm" onClick={closeAttemptsModal}>Close</Link>
        
          </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Customer_support;
