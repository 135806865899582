import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import CircularProgress from '@mui/material/CircularProgress'; // Import loader component
import Typography from '@mui/material/Typography'; // For displaying text

// Material Dashboard 2 React example components
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './sales_funnel_details.css';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from 'sweetalert2';

import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";

const empId = secureLocalStorage.getItem('emp_id');

function MissingFollowup() {
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [inner_page_details, get_inner_page_details] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [remark, setremark] = useState('');
  const [id, setid] = useState('');
  const [openModal, setopenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    sales_inner_page_details();
  }, []);

  useEffect(() => {
    sales_inner_page_filter();
  }, [end_date, start_date]);

  const sales_inner_page_details = async () => {
    try {
      setLoading(true);
      const response = await dashboardApi.get('count-sales-followup', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        get_inner_page_details(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const sales_inner_page_filter = async () => {
    try {
      setLoading(true);
      const response = await dashboardApi.get('count-sales-followup', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        get_inner_page_details(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const add_remark = (id) => {
    setid(id);
    setopenModal(true);
  }

  const handle_close = () => {
    setopenModal(false);
    setid('');
    setremark('');
  }

  const handle_save = async () => {
    const errors = {};
    if (!remark) {
      errors.remark = 'Remark is required.';
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('id', id);
    formData.append('remark', remark);
    formData.append('emp_id', empId);
    try {
      const response = await dashboardApi.post('add-remark-on-followups', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handle_close();
        sales_inner_page_details();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div className="container-fluid mt-4">
          <Link className='btn btn-info btn-sm' to='/sales-dashboard'>Back</Link>
          <div className="card mb-4">
            <div className="card-body">
              <div className='row mb-3'>
                <div className='col-md-4'>
                  <input type='date'
                    className='form-control'
                    value={start_date}
                    onChange={(e) => setstart_date(e.target.value)}
                  />
                </div>
                <div className='col-md-4'>
                  <input type='date'
                    className='form-control'
                    value={end_date}
                    onChange={(e) => setend_date(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div className="card">
            <div className="card-body">
              {loading ? ( // Show loader while loading
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <CircularProgress />
                  <Typography variant="h6" style={{ marginTop: '10px' }}>
                    Loading...
                  </Typography>
                </div>
              ) : (
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <td>Client Name</td>
                      <td>Company</td>
                      <td>Followup Taken</td>
                      <td>Followup Date</td>
                      <td>Emp Name</td>
                      <td>Manager</td>
                      <td style={{minWidth:'230px'}}>Emp Remark</td>
                      <td style={{minWidth:'230px'}}>Manager Remark</td>
                      <td style={{minWidth:'230px'}}>CEO Remark</td>
                      <td>Created Date</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {inner_page_details.map((row) => (
                      <tr key={row.id}>
                        <td>{row.client_name}</td>
                        <td>{row.comp_name}</td>
                        <td>{row.is_followup_taken}</td>
                        <td>{row.missing_followup_date}</td>
                        <td>{row.emp_name}</td>
                        <td>{row.manager_details}</td>
                        <td style={{minWidth:'230px'}}>{row.emp_remark}</td>
                        <td style={{minWidth:'230px'}}>{row.manager_remark}</td>
                        <td style={{minWidth:'230px'}}>{row.superadmin_remark}</td>
                        <td>{row.created_date}</td>
                        <td style={{ fontSize: '12px' }}>
                          {row.client_type === 1 && 
                            <Link to={`/client-profile/${row.client_id}`} className='btn btn-info btn-sm'>Profile</Link>
                          }
                          {row.client_type === 2 && 
                            <Link to={`/mature-client-profile/${row.client_id}`} className='btn btn-info btn-sm'>Profile</Link>
                          }
                          &nbsp; &nbsp; &nbsp;
                          <td><button className='btn btn-info btn-sm' onClick={() => add_remark(row.id)}>Remark</button></td>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
          <DialogTitle>Remark</DialogTitle>
          <DialogContent>
            <form>
              <div className="form-group col-md-12">
                <label htmlFor="short name" style={{ fontSize: '16px', height: '20px' }}>Remark</label>
                <textarea rows={5} cols={5} style={{ height: '50px' }}
                  value={remark}
                  onChange={(e) => setremark(e.target.value)}
                  className="form-control"
                  placeholder="remark"
                />
                {validationErrors.remark && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
              </div>
            </form>
          </DialogContent>

          <DialogActions>
            <Link className="btn btn-danger" onClick={handle_close}>
              Close
            </Link>
            <Link className="btn btn-info" onClick={handle_save}>
              Add
            </Link>
          </DialogActions>
        </Dialog>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {/* Additional content can go here */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* Additional content can go here */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default MissingFollowup;
